/**
 * Aktuellen Wert eines SELECT-Formfields auslesen
 * @param {Element} obj DOM des SELECT
 * @return {string}
 */
hx.inputSelectGetValue = function (obj) {
    return hx.getSelectFieldData(obj, "value");
};

/**
 * Aktuellen Text eines SELECT-Formfields auslesen
 * @param {Element} obj                - DOM des SELECT
 * @return {string}
 */
hx.inputSelectGetText = function (obj) {
    return hx.getSelectFieldData(obj, "text");
};

/**
 * Aktuellen Wert eines SELECT-Formfields setzen
 * @param {Element} obj               - DOM des SELECT
 * @param {string} value          - Value im Selectfield
 * @return {string}
 */
hx.inputSelectSetValue = function (obj, value) {
    return hx.setSelectFieldData(obj, value, "value");
};

/**
 * Aktuellen Wert eines SELECT-Formfields an die Stelle des uebergebenen Texts setzen
 * @param {Element} obj               - DOM des SELECT
 * @param {string} value          - Text im Selectfield
 * @return {string}
 */
hx.inputSelectSetText = function (obj, value) {
    return hx.setSelectFieldData(obj, value, "text");
};

/**
 * Alle Werte eines SELECT-Formfields als Array holen
 * @param {Element} obj                - DOM des SELECT
 * @return Array ergebnis
 */
hx.inputSelectGetValueArray = function (obj) {
    return hx.getSelectFieldDataAsArray(obj, "value");
};

/**
 * Alle Texte eines SELECT-Formfields als Array holen
 * @param {Element} obj                - DOM des SELECT
 * @return Array ergebnis
 */
hx.inputSelectGetTextArray = function (obj) {
    return hx.getSelectFieldDataAsArray(obj, "text");
};

/**
 * Aktuelle Position des Cursors in Textarea/Input auslesen
 * @param {Element} obj                - DOM
 * @return {number}
 */
hx.inputTextGetCaretPos = function (obj) {
    var CaretPos = 0;
    // IE Support
    if (document.selection) {
        obj.focus();
        var Sel = document.selection.createRange();
        Sel.moveStart('character', -obj.value.length);
        CaretPos = Sel.text.length;
    } else if (obj.selectionStart || obj.selectionStart == '0') {
        CaretPos = obj.selectionStart;
    }
    return (CaretPos);
};

/**
 * Aktuelle Position des Cursors in Textarea/Input setzen
 * @param {Element} obj                - DOM
 * @param {number} pos            - Position
 */
hx.inputTextSetCaretPos = function (obj, pos) {
    if (obj.setSelectionRange) {
        obj.focus();
        obj.setSelectionRange(pos, pos);
    } else if (obj.createTextRange) {
        var range = obj.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
};

/**
 * Inhalt eines JS-Arrays(Objekts) zu einem Formular hinzufuegen
 * hx.array2input(new Array(1,2,3),"myArray",document.f)
 * @param arr       - Das Array/Objekt
 * @param name      - Name im Formular
 * @param form      - Das Formular welchem Felder hinzugefuegt werden
 * @returns {string}
 */
hx.array2input = function (arr, name, form) {
    var t = "";
    for (var k in arr) {
        var v = arr[k];
        if (hx.is_array(v) || hx.is_object(v)) {
            t += "" + hx.array2input(v, name + "[" + k + "]", form) + "";
        } else {
            if (v === true) {
                v = 1;
            } else if (v === false) {
                v = 0;
            }
            var input = document.createElement("input");
            input.name = name + "[" + k + "]";
            input.type = "hidden";
            input.value = v;
            form.appendChild(input);
            t += input.outerHTML;
        }
    }
    return t;
};

/**
 * Aktuellen Wert eines SELECT-Formfields auslesen
 * @param {Element} obj DOM des SELECT
 * @param {string} what "value"=>liest den Wert aus dem Element aus und "text"=>liest den Text der an der Oberflaeche ausgegeben wird aus
 * @return {string}
 */
hx.getSelectFieldData = function (obj, what) {
    if (!what) what = "value";
    var arr = obj.options;
    return arr[obj.selectedIndex][what];
};

/**
 * Werte eines SELECT-Formfields lesen als Array
 * @param {Element} obj DOM des SELECT
 * @param {string} what "value"=>liest den Wert aus dem Element aus und "text"=>liest den Text der an der Oberflaeche ausgegeben wird aus
 * @return Array
 */
hx.getSelectFieldDataAsArray = function (obj, what) {
    if (!what) what = "value";
    var arr = obj.options;
    var resArr = new Array();
    for (var i = 0; i < arr.length; i++) {
        resArr.push(arr[i][what]);
    }
    return resArr;
};

/**
 * Aktuellen Wert eines SELECT-Formfields setzen
 * @param {object} obj DOM des SELECT
 * @param value
 * @param what         "value"=>sucht nach dem Wert aus dem Element und "text" sucht nach dem Wert der an der Oberflaeche ausgegeben wird aus
 * @returns {boolean}
 */
hx.setSelectFieldData = function (obj, value, what) {
    if (!what) what = "value";
    var arr = obj.options;
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][what] == value) {
            obj.selectedIndex = i;
            return true;
        }
    }
    return false;
};