/**
 * Hintergrundshade wird eingefuegt und uebergebenes DOM-Obj im Vordergrund zentriert angezeigt.
 * @param {object} obj          - Ein DOM-Obj mit definierter Hoehe und Breite (zB DIV oder IMG)
 * @param {string} bgcolor  - Farbe des Overlays (optional, default black)
 * @param {number} opacity  - Durchsichtigkeit von 0-1 (optional, default 0.75)
 * @param {number} zindex   - zindex  (optional, default 10000)
 * @param onClickShadeClose
 * @param closefunction
 */
hx.guiOverlayOpen = function (obj, bgcolor, opacity, zindex, onClickShadeClose, closefunction) {
    var el_div;
    if (document.getElementById("hx_lightbox_bg")) {
        el_div = document.getElementById("hx_lightbox_bg");
    } else {
        el_div = document.createElement("div");
        document.body.appendChild(el_div);
    }
    if (bgcolor == null) {
        bgcolor = "black";
    }
    if (opacity == null) {
        opacity = 0.5;
    }
    if (zindex == null) {
        zindex = 10000;
    }
    var el_show = obj;
    // IE 6
    if (typeof document.body.style.maxHeight == "undefined") {
        el_show.style.position = "absolute";
        el_div.style.position = "absolute";
        el_div.style.height = el_div.style.width = "5000px";
    } else {
        el_show.style.position = "fixed";
        el_div.style.position = "fixed";
        el_div.style.height = el_div.style.width = "100%";
    }
    el_div.id = "hx_lightbox_bg";
    //    el_div.style.position = "fixed";
    el_div.style.backgroundColor = bgcolor;
    el_div.style.top = el_div.style.left = 0;
    el_div.style.opacity = opacity;
    el_div.style.filter = "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + (opacity * 100) + ")";
    el_div.style.zIndex = zindex;
    el_div.style.display = "";
    el_show.style.display = "";
    el_show.style.left = el_show.style.top = "50%";
    el_show.style.marginTop = -(hx.cint(el_show.style.height) / 2) + "px";
    el_show.style.marginLeft = -(hx.cint(el_show.style.width) / 2) + "px";
    el_show.style.marginRight = el_show.style.marginBottom = 0;
    el_show.style.zIndex = zindex + 1;
    if (closefunction) {
        el_show.overlayCloseFunction = closefunction;
    }
    if (!el_div.onclick && onClickShadeClose == true) {
        el_div.onclick = function () {
            hx.guiOverlayClose(el_show);
        };
    }
};

/**
 * Hintergrundshade mit daraufliegendem DOM-Obj ausblenden
 * @param {Element} obj Das auszublendende DOM-Obj
 * @return {number}
 */
hx.guiOverlayClose = function (obj) {
    var el_show = obj;
    if (el_show.overlayCloseFunction) {
        el_show.overlayCloseFunction();
    }
    document.getElementById("hx_lightbox_bg").style.display = "none";
    el_show.style.display = "none";
};


/**
 * Text in Blockelement mit fixer Breite beschneiden mit ...
 * @param {string} str
 * @param {number} px auf Breite beschneiden
 * @param {string} align Wo beschneiden (right,middle)
 * @param {string} className
 * @returns {string}
 */
hx.guiSetEllipsis = function (str, px, align, className) {
    var span = document.createElement("span");
    span.className = className;
    span.style.visibility = 'hidden';
    span.style.padding = '0px';
    span.style.whiteSpace = 'nowrap';
    span.innerHTML = str + '';
    document.body.appendChild(span);
    var c = 0;
    var s = '';
    var n;
    var w = span.offsetWidth;
    if (w > px) {
        if (align == 'center') {
            c = Math.floor(str.length / 2);
            for (n = c - 2; n > 0; n--) {
                s = str.substr(0, n) + '...' + str.substr(str.length - n, 10000);
                span.innerHTML = s;
                if (span.offsetWidth <= px) {
                    break;
                }
            }
            str = s;
        }
        if (align == 'left') {
            for (n = str.length - 1; n > 0; n = n - 2) {
                s = str.substr(0, n) + '...';
                span.innerHTML = s;
                if (span.offsetWidth <= px) break;
            }
            str = s;
        }
        if (align == 'right') {
            for (n = str.length - 1; n > 0; n = n - 2) {
                s = '...' + str.substr(str.length - n, 10000);
                span.innerHTML = s;
                if (span.offsetWidth <= px) break;
            }
            str = s;
        }
    }
    document.body.removeChild(span);
    return hx.trim(str);
};

/**
 * Loadinganimation einblenden
 * Parent-Element sollte position absolute/relative haben!
 * @param name      eindeutiger Name
 * @param parent    Element in welches es eingebettet wird
 * @param w         Breite
 * @param h         Hoehe
 * @param r         Rotwert
 * @param g         Gruenwert
 * @param b         Blauwert
 */
hx.guiLoaderIconDisplay = function (name, parent, w, h, r, g, b) {
    if (!r) r = 255;
    if (!g) g = 230;
    if (!b) b = 129;
    if (!hx.guiLoaderIconDisplay.loaders) hx.guiLoaderIconDisplay.loaders = new Object();
    if (!hx.guiLoaderIconDisplay.loadersColors) hx.guiLoaderIconDisplay.loadersColors = new Object();
    if (!hx.guiLoaderIconDisplay.loadersRunning) hx.guiLoaderIconDisplay.loadersRunning = new Object();
    if (!hx.guiLoaderIconDisplay.toValid) {
        hx.guiLoaderIconDisplay.toValid = function (val) {
            if (val < 0) {
                return 0;
            } else if (val > 255) {
                val = 255;
            }
            return val;
        };
    }
    if (hx.guiLoaderIconDisplay.loaders[name]) return;
    hx.guiLoaderIconDisplay.loaders[name] = new Array();
    hx.guiLoaderIconDisplay.loadersColors[name] = new Array();
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r) + ',' + hx.guiLoaderIconDisplay.toValid(g) + ',' + hx.guiLoaderIconDisplay.toValid(b) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 10) + ',' + hx.guiLoaderIconDisplay.toValid(g - 10) + ',' + hx.guiLoaderIconDisplay.toValid(b - 10) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 20) + ',' + hx.guiLoaderIconDisplay.toValid(g - 20) + ',' + hx.guiLoaderIconDisplay.toValid(b - 20) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 30) + ',' + hx.guiLoaderIconDisplay.toValid(g - 30) + ',' + hx.guiLoaderIconDisplay.toValid(b - 30) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 40) + ',' + hx.guiLoaderIconDisplay.toValid(g - 40) + ',' + hx.guiLoaderIconDisplay.toValid(b - 40) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 50) + ',' + hx.guiLoaderIconDisplay.toValid(g - 50) + ',' + hx.guiLoaderIconDisplay.toValid(b - 50) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 40) + ',' + hx.guiLoaderIconDisplay.toValid(g - 40) + ',' + hx.guiLoaderIconDisplay.toValid(b - 40) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 30) + ',' + hx.guiLoaderIconDisplay.toValid(g - 30) + ',' + hx.guiLoaderIconDisplay.toValid(b - 30) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 20) + ',' + hx.guiLoaderIconDisplay.toValid(g - 20) + ',' + hx.guiLoaderIconDisplay.toValid(b - 20) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 10) + ',' + hx.guiLoaderIconDisplay.toValid(g - 10) + ',' + hx.guiLoaderIconDisplay.toValid(b - 10) + ')');
    var width_elem = hx.cint(w / 10 - 4);
    var height_elem = h;
    var div = document.createElement('DIV');
    div.style.width = width_elem + 'px';
    div.style.height = height_elem + 'px';
    div.style.marginLeft = '2px';
    div.style.left = '-2px';
    div.style.border = '1px solid rgb(' + hx.guiLoaderIconDisplay.toValid(r - 55) + ',' + hx.guiLoaderIconDisplay.toValid(g - 55) + ',' + hx.guiLoaderIconDisplay.toValid(b - 55) + ')';
    if (w < 60) div.style.border = '1px solid transparent';
    div.style.position = 'absolute';
    div.style.backgroundColor = hx.guiLoaderIconDisplay.loadersColors[name][0];
    hx.guiLoaderIconDisplay.loaders[name].push(div);
    parent.appendChild(div);
    for (var i = 1; i < 10; i++) {
        div = div.cloneNode(true);
        div.style.backgroundColor = hx.guiLoaderIconDisplay.loadersColors[name][i];
        hx.guiLoaderIconDisplay.loaders[name].push(div);
        parent.appendChild(div);
        div.style.left = div.previousSibling.offsetLeft + div.previousSibling.offsetWidth + 'px';
    }
    hx.guiLoaderIconDisplay.loadersRunning[name] = setInterval(function () {
        var length = 0;
        for (var i in hx.guiLoaderIconDisplay.loadersColors[name]) {
            length++;
        }
        var t = hx.guiLoaderIconDisplay.loadersColors[name][length - 1];
        hx.guiLoaderIconDisplay.loadersColors[name].splice(length - 1, 1);
        hx.guiLoaderIconDisplay.loadersColors[name].unshift(t);

        for (var i in hx.guiLoaderIconDisplay.loaders[name]) {
            hx.guiLoaderIconDisplay.loaders[name][i].style.backgroundColor = hx.guiLoaderIconDisplay.loadersColors[name][i];
        }
        hx.forceBrowserRepaint();
    }, 100);
};

/**
 * Loadinganimation beenden
 * @param name      eindeutiger Name
 */
hx.guiLoaderIconKill = function (name) {
    clearInterval(hx.guiLoaderIconDisplay.loadersRunning[name]);
    hx.guiLoaderIconDisplay.loadersRunning[name] = null;
    for (var i in hx.guiLoaderIconDisplay.loaders[name]) {
        if (hx.guiLoaderIconDisplay.loaders[name][i] && hx.guiLoaderIconDisplay.loaders[name][i].parentNode) hx.guiLoaderIconDisplay.loaders[name][i].parentNode.removeChild(hx.guiLoaderIconDisplay.loaders[name][i]);
    }
    hx.guiLoaderIconDisplay.loadersColors[name] = null;
    hx.guiLoaderIconDisplay.loaders[name] = null;
};


/**
 * SVG Container erstellen
 * @param {object} parent_element DOM-Obj fuer Einbettung
 * @param {number} w              Breite in px
 * @param {number} h              Hoehe in px
 * @return {number|HTMLElement} null, falls nicht unterstuetzt
 */
hx.guiCreateSVGContainer = function (parent_element, w, h) {
    if (hx.isSVGSupported()) {
        var ns = 'http://www.w3.org/2000/svg';
        var svg = document.createElementNS(ns, 'svg');
        svg.setAttribute('width', w);
        svg.setAttribute('height', h);
        parent_element.appendChild(svg);
        return svg;
    } else {
        return null;
    }
};

hx._setFocus = function (obj) {
    hx.foreach(obj.getElementsByTagName('*'), function (elem, key) {
        hx.eventAddCall(elem, "focus", function (event) {
            obj.focus();
        });
    });
};

hx._objEnter = function (fn) {

    return function (evt) {
        var relTarget = evt.relatedTarget;
        if (this === relTarget || hx.objIsAChildOf(this, relTarget)) {
            return;
        }
        fn.call(this, evt);
    }
};

/**
 * Hinzufuegen von auszufuehrenden Funktionen zu Events.
 * Bereits hinzugefuegte werden nicht geloescht.
 * Neue Events: Mouseenter,Mouseleave =>
 *                  Events beziehen sich nur auf das definierte Objekt. Kindelemente verursachen kein "Mouseleave"
 *              Focusin,Focusout =>
 *                  Events beziehen sich nur auf das definierte Objekt. Kindelemente verursachen kein "Focusout"
 * @param {Element} obj DOM-Objekt
 * @param {string} types Eventtyp ohne "on" z.B. "click" statt "onclick" => bei mehreren Events diese beistrichtgetrennt angeben
 * @param {string} fn Auszufuehrende Funktion
 *
 */
hx.eventAddCall = function (obj, types, fn) {
    types = hx.replace(types, " ", "");
    types = hx.explode(",", types);
    hx.foreach(types, function (type) {
        if (obj.addEventListener) {
            if (type == "mouseenter") {
                obj.addEventListener("mouseover", hx._objEnter(fn), false);
            } else if (type == "mouseleave") {
                obj.addEventListener("mouseout", hx._objEnter(fn), false);
            } else if (type == "focusin") {
                obj.addEventListener("focus", fn, false);
                hx._setFocus(obj);
            } else if (type == "focusout") {
                obj.addEventListener("blur", fn, false);
            } else {
                obj.addEventListener(type, fn, false);
            }
        } else if (obj.attachEvent) {
            obj["e" + type + fn] = fn;
            obj[type + fn] = function (e) {
                if (!e) e = window.event;
                if (!e.target) e.target = e.srcElement;
                if (!e.which) e.which = e.keyCode;
                obj["e" + type + fn](e);
            };
            if (type == "focusin") {
                obj.attachEvent("onfocus", fn);
                hx._setFocus(obj);
            } else if (type == "focusout") {
                obj.attachEvent("onblur", fn);
            } else {
                obj.attachEvent("on" + type, obj[type + fn]);
            }
        }
        if (!obj.hxEventFnList) obj.hxEventFnList = new Object();
        obj.hxEventFnList[hx.count(obj.hxEventFnList)] = fn;
    });
};

/**
 * Blockiert den doppelklick auf ein Element (zb. Button, Link, ...)
 * @param objArr
 */
hx.eventDblClickBlock = function (objArr) {
    hx.foreach(objArr, function (link) {
        if (link.getAttribute('eventDblClickBlock') === "0" || link.getAttribute('eventDblClickBlock') === "1") return true;
        link.setAttribute('eventDblClickBlock', "0");
        hx.eventAddCall(link, 'click', function (e) {
            if (!e) e = window.event;
            if (!e.target) e.target = e.srcElement;
            if (this.getAttribute('eventDblClickBlock') == "0") {
                this.setAttribute('eventDblClickBlock', "1");
                var thisElem = this;
                setTimeout(function () {
                    thisElem.setAttribute('eventDblClickBlock', "0");
                }, 1000);
            } else {
                e.preventDefault();
                return false;
            }
        });
    });
};

/**
 * Entfernen einer Funktion zu einem Event.
 * Bereits hinzugefuegte werden nicht geloescht
 * @param {Element} obj DOM-Objekt
 * @param {string} type Eventtyp ohne "on" z.B. "click" statt "onclick"
 * @param {string} fn Funktionsname der auszufuehrenden Funktion
 */
hx.eventRemoveCall = function (obj, type, fn) {
    if (obj.removeEventListener) {
        obj.removeEventListener(type, fn, false);
    } else if (obj.detachEvent) {
        obj.detachEvent("on" + type, obj[type + fn]);
        obj[type + fn] = null;
        obj["e" + type + fn] = null;
    }
};

/**
 * Event abbrechen und jegliche weiterleitung zu anderen Objekten verhindern
 * @param {Event} e Event
 */
hx.eventCancelBubble = function (e) {
    if (!e) e = window.event;
    try {
        e.stopPropagation();
    } catch (ex) { }
    try {
        e.cancelBubble = true;
    } catch (ex) { }
};

/**
 * Alle DOM-ELemente finden die ein bestimmtes Attribut haben
 * @param {string} attr         Das zu suchende Attribut
 * @param {string} tagname      Attribut nur in bestimmtem Tag suchen (schneller... fuer IE)
 * @param {string} cache        cachen, default=false
 * @param {string} objSearchIn  Suche eingrenzen in einem Objekt (cache deaktiviert)
 * @param {string} attrValue    Suche eingrenzen auf Wert des Attributs (cache deaktiviert)
 * @return Array
 */
hx.objByAttributeName = function (attr, tagname, cache, objSearchIn, attrValue) {
    if (!tagname) tagname = "*";
    if (hx.isFastBrowser()) {
        if (!objSearchIn) objSearchIn = document;
        if (attrValue) {
            var objs = objSearchIn.querySelectorAll(tagname + "[" + attr + "=\"" + attrValue + "\"]");
        } else {
            var objs = objSearchIn.querySelectorAll(tagname + "[" + attr + "]");
        }
        var erg = new Array();
        for (var i = 0; i < objs.length; i++) {
            erg.push(objs[i]);
        }
        return erg;
    }
    if (objSearchIn || attrValue) cache = false;
    if (cache) {
        if (!hx.objByAttributeName.cache) hx.objByAttributeName.cache = new Object();
        if (hx.objByAttributeName.cache[attr]) return hx.objByAttributeName.cache[attr];
    }
    if (objSearchIn) {
        var all = objSearchIn.getElementsByTagName(tagname);
    } else {
        var all = document.getElementsByTagName(tagname);
    }
    var erg = new Array();
    for (var i = 0; i < all.length; i++) {
        if (hx.is_string(all[i].getAttribute(attr))) {
            if (attrValue) {
                if (attrValue == all[i].getAttribute(attr)) erg.push(all[i]);
            } else {
                erg.push(all[i]);
            }
        }
    }
    if (cache) hx.objByAttributeName.cache[attr] = erg;
    return erg;
};

/**
 * Zwingt Browser durch temporaeres Einfuegen eines DOM-Elements die Seite neu zu rendern
 */
hx.forceBrowserRepaint = function () {
    var t = document.createTextNode(' ');
    document.body.appendChild(t);
    document.body.removeChild(t);
};

/**
 * Abkuerzung fuer document.getElementById().
 * Hat einen Cache um Performance fuer aeltere Browser zu verbessern
 * @param {string} id id des Tags
 * @param {boolean} cache caching ein/ausschalten
 * @return {Element}
 */
hx.obj = function (id, cache) {
    return document.getElementById(id);
};

/**
 * Ein DOM-Element vor bestimmter Stelle einfuegen
 * @param objNew  - Das einzufuegende Element
 * @param objTarget - Das Zielelement vor dem es eingefuegt wird
 */
hx.objInsertBefore = function (objNew, objTarget) {
    objTarget.parentNode.insertBefore(objNew, objTarget);
};

/**
 * Ein DOM-Element nach bestimmter Stelle einfuegen
 * @param objNew  - Das einzufuegende Element
 * @param objTarget - Das Zielelement nach dem es eingefuegt wird
 */
hx.objInsertAfter = function (objNew, objTarget) {
    objTarget.parentNode.insertBefore(objNew, objTarget.nextSibling);
};

/**
 * Ein DOM-Objekt loeschen
 * @param obj zu loeschendes DOM-Objekt
 */
hx.objRemove = function (obj) {
    obj.parentNode.removeChild(obj);
};

/**
 * Pruefen ob Dom-Objekte in anderen Elementen enhalten sind
 * @param parent - Objekt in dem gesucht wird
 * @param child  - Objekt welches gesucht wird
 * @param isDirectDescendent - Soll es ein direktes Unterelement sein
 * @returns {boolean}
 */
hx.objIsAChildOf = function (parent, child, isDirectDescendent) {
    if (parent === child) {
        return false;
    }
    while (child && child !== parent) {
        child = child.parentNode;
        if (isDirectDescendent === true) break;
    }
    return child === parent;
};

/**
 * Funktioniert wie document.querySelectorAll
 * Holt alle DOM-Objekte auf die der uebergebene CSS-Selector zutrifft
 * @param {string} query  CSS Selector
 * @param {object} obj    DOM-Objekt in dem gesucht werden soll
 * @returns Array
 */
hx.objByCssSelector = function (query, obj) {
    if (obj) return $(obj).find(query).toArray();
    return $(query).toArray();
};


hx._fadeGetUniqueId = function (obj) {
    if (!hx._fadeGetUniqueId.cache) hx._fadeGetUniqueId.cache = new Array();
    for (var i in hx._fadeGetUniqueId.cache) {
        if (hx._fadeGetUniqueId.cache[i] == obj) return hx.cint(i);
    }
    hx._fadeGetUniqueId.cache.push(obj);
    return hx._fadeGetUniqueId.cache.length - 1;
};

/**
 * Blendet DOM-Obj ein.
 * @param {Element} obj           Das einzublendende DOM-Obj
 * @param {number} cycles         In wie viel Schritten soll das Einblenden passieren? 1 Schritt ca. 1 Millisekunde
 * @param {function} successFunc  Funktion die am Ende der Animation ausgefuehrt werden soll
 */
hx.fadeIn = function (obj, cycles, successFunc) {
    if (!hx.fadeIn.fade) hx.fadeIn.fade = new Object;
    if (!hx.fadeOut.fade) hx.fadeOut.fade = new Object;
    cycles = hx.cint(cycles) + 1;
    clearInterval(hx.fadeOut.fade[obj]);
    if (hx.isFastBrowser()) {
        obj.style.opacity = 0;
    } else {
        obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    }
    var step = 1 / cycles;
    var cyclesDone = 1;
    var objCacheKey = hx._fadeGetUniqueId(obj);
    hx.fadeIn.fade[objCacheKey] = setInterval(function () {
        if (cyclesDone == cycles) {
            clearInterval(hx.fadeIn.fade[objCacheKey]);
            if (hx.isFastBrowser()) {
                obj.style.opacity = 0;
            } else {
                obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
            }
            if (successFunc) successFunc();
        }
        if (hx.isFastBrowser()) {
            obj.style.opacity = step * cyclesDone;
        } else {
            obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + (step * cyclesDone * 100) + ')';
        }
        cyclesDone++;
    }, 1);
};

/**
 * Blendet DOM-Obj aus.
 * @param {Element} obj            Das auszublendende DOM-Obj
 * @param {number} cycles          In wie viel Schritten soll das Ausblenden passieren? 1 Schritt ca. 1 Millisekunde
 * @param {function} successFunc   Funktion die am Ende der Animation ausgefuehrt werden soll
 */
hx.fadeOut = function (obj, cycles, successFunc) {
    if (!hx.fadeIn.fade) hx.fadeIn.fade = new Object;
    if (!hx.fadeOut.fade) hx.fadeOut.fade = new Object;
    cycles = hx.cint(cycles) + 1;
    clearInterval(hx.fadeIn.fade[obj]);
    if (hx.isFastBrowser()) {
        obj.style.opacity = 1;
    } else {
        obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    var step = 1 / cycles;
    var cyclesDone = 1;
    var objCacheKey = hx._fadeGetUniqueId(obj);
    hx.fadeOut.fade[objCacheKey] = setInterval(function () {
        if (cyclesDone == cycles) {
            clearInterval(hx.fadeOut.fade[objCacheKey]);
            //obj.style.display = 'none';
            if (hx.isFastBrowser()) {
                obj.style.opacity = 0;
            } else {
                obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
            }
            if (successFunc) successFunc();
        }
        if (hx.isFastBrowser()) {
            obj.style.opacity = 1 - step * cyclesDone;
        } else {
            obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + ((1 - step * cyclesDone) * 100) + ')';
        }
        cyclesDone++;
    }, 1);
};

/**
 * Prueft, on ein DOM-Element sichtbar ist
 * @param {dom-element} o
 * @returns {boolean}
 */
hx.isVisible = function (o) {
    while (true) {
        if (o.style.display == 'none' || o.style.visibility == 'hidden') return false;
        if (o.filters) {
            if (o.filters.item("DXImageTransform.Microsoft.Alpha").opacity == 0) return false;
        }
        if (o.style.opacity) {
            if (o.style.opacity == 0) return false;
        }
        if (o == document.body) break;
        o = o.parentNode;
    }
    return true;
};





/**
 * setzt ein Feld der alten Form-Bibliothek auf Mussfeld/Kannfeld
 * @deprecated
 * @see ..new HXFormField...('fieldname','formname').setOptional(true/false);
 * @param {DOM-Element} obj
 * @param {Boolean} allowNull
 */
hx.guiSetInputFieldStyleAllowNull = function (obj, allowNull) {
    if (!obj) return;
    var tagname = obj.tagName.toLowerCase();
    if (tagname == 'select') {
        if (allowNull) {
            hx.classNameRemove(obj, 'formselectnotnull');
            hx.classNameAdd(obj, 'formselect');

            let onblurVal = obj.getAttribute('onblur');
            let onFocusVal = obj.getAttribute('onfocus');

            onblurVal = hx.replace(onblurVal, 'formselectnotnull', 'formselect');
            onFocusVal = hx.replace(onFocusVal, 'formselectnotnullfocus', 'formselectfocus');

            obj.setAttribute('onblur', onblurVal);
            obj.setAttribute('onfocus', onFocusVal);

        } else {
            hx.classNameRemove(obj, 'formselect');
            hx.classNameAdd(obj, 'formselectnotnull');

            let onblurVal = obj.getAttribute('onblur');
            let onFocusVal = obj.getAttribute('onfocus');

            onblurVal = hx.replace(onblurVal, 'formselect', 'formselectnotnull');
            onFocusVal = hx.replace(onFocusVal, 'formselectfocus', 'formselectnotnullfocus');

            obj.setAttribute('onblur', onblurVal);
            obj.setAttribute('onfocus', onFocusVal);

        }
    } else {
        if (allowNull) {

            hx.classNameRemove(obj, 'formtextnotnull');
            hx.classNameAdd(obj, 'formtext');

            let onblurVal = obj.getAttribute('onblur');
            let onFocusVal = obj.getAttribute('onfocus');

            onblurVal = hx.replace(onblurVal, 'formtextnotnull', 'formtext');
            onFocusVal = hx.replace(onFocusVal, 'formtextnotnullfocus', 'formtextfocus');

            obj.setAttribute('onblur', onblurVal);
            obj.setAttribute('onfocus', onFocusVal);

        } else {
            hx.classNameRemove(obj, 'formtext');
            hx.classNameAdd(obj, 'formtextnotnull');

            let onblurVal = obj.getAttribute('onblur');
            let onFocusVal = obj.getAttribute('onfocus');

            onblurVal = hx.replace(onblurVal, 'formtext', 'formtextnotnull');
            onFocusVal = hx.replace(onFocusVal, 'formtextfocus', 'formtextnotnullfocus');

            obj.setAttribute('onblur', onblurVal);
            obj.setAttribute('onfocus', onFocusVal);
        }
    }
};

/**
 * Color-Objekt
 * Erlaubt Eingabe von verschiedenen Farbformaten
 * #cccccc
 * rgb(r,g,b)
 * rgba(r,g,b,a)
 * r,g,b
 * index fuer vordefinierte Farbwerte
 * index.kommastelle fuer vordefinierte Werte mit aufhellung
 * @param {string} any_color die Farbe
 */
hx.Color = function (any_color) {
    this.r = 0;
    this.g = 0;
    this.b = 0;
    this.a = 0;
    var a, orig_color, heller = 0;
    if (any_color == undefined) any_color = "white";
    any_color = (any_color + '').toLowerCase();
    // Named colors:
    if (any_color == "black") any_color = "#000000";
    if (any_color == "white") any_color = "#ffffff";
    if (any_color == "red") any_color = "#ff0000";
    if (any_color == "green") any_color = "#00ff00";
    if (any_color == "blue") any_color = "#0000ff";
    if (any_color == "yellow") any_color = "#ffff00";
    if (any_color == "transparent") any_color = "rgba(255,255,255,0)";
    // map Colorindex
    if (any_color.indexOf('rgb') < 0 && any_color.indexOf('#') < 0 && any_color.indexOf(',') < 0) {
        orig_color = any_color;
        any_color = parseInt(any_color, 10) % 14;
        heller = parseFloat(orig_color % 14) - any_color;
        heller = heller * 10;
        switch (any_color) {
            case 0:
                any_color = "#e9d666";
                break;
            case 1:
                any_color = "#c6cb6f";
                break;
            case 2:
                any_color = "#c88a10";
                break;
            case 3:
                any_color = "#aecce7";
                break;
            case 4:
                any_color = "#7299c6";
                break;
            case 5:
                any_color = "#cbb676";
                break;
            case 6:
                any_color = "#d4711a";
                break;
            case 7:
                any_color = "#ffc40b";
                break;
            case 8:
                any_color = "#8b9d3c";
                break;
            case 9:
                any_color = "#bf311a";
                break;
            case 10:
                any_color = "#16619e";
                break;
            case 11:
                any_color = "#003b77";
                break;
            case 12:
                any_color = "#b39601";
                break;
            case 13:
                any_color = "#8d0624";
                break;
            default:
                any_color = "#e9d666";
                break;
        }
    }
    if (any_color.indexOf('rgba') >= 0) {          // RGBA(r,g,b,a)
        any_color = any_color.replace(/rgba/, '');
        any_color = any_color.replace(/\(/, '');
        any_color = any_color.replace(/\)/, '');
        a = any_color.split(',');
        this.r = parseInt(a[0], 10);
        this.g = parseInt(a[1], 10);
        this.b = parseInt(a[2], 10);
        this.a = parseFloat(a[3]);
    } else if (any_color.indexOf('rgb') >= 0) {     // RGB(r,g,b)
        any_color = any_color.replace(/rgb/, '');
        any_color = any_color.replace(/\(/, '');
        any_color = any_color.replace(/\)/, '');
        a = any_color.split(',');
        this.r = parseInt(a[0], 10);
        this.g = parseInt(a[1], 10);
        this.b = parseInt(a[2], 10);
        this.a = 1;
    } else if (any_color.indexOf('#') >= 0) {       // #RRGGBB
                                                    // HEX
        var res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(any_color);
        this.r = parseInt(res[1], 16);
        this.g = parseInt(res[2], 16);
        this.b = parseInt(res[3], 16);
        this.a = 1;
    } else {                                      // r,g,b
        a = any_color.split(',');
        this.r = parseInt(a[0], 10);
        this.g = parseInt(a[1], 10);
        this.b = parseInt(a[2], 10);
        this.a = 1;
    }
    if (heller > 0) {
        this.r = this.r + 15 * heller;
        this.g = this.g + 15 * heller;
        this.b = this.b + 15 * heller;
    }
    if (this.r < 0) this.r = 0;
    if (this.r > 255) this.r = 255;
    if (this.g < 0) this.g = 0;
    if (this.g > 255) this.g = 255;
    if (this.b < 0) this.b = 0;
    if (this.b > 255) this.b = 255;
    if (this.a < 0) this.a = 0;
    if (this.a > 1) this.a = 1;
    this.r = parseInt(this.r, 10);
    this.g = parseInt(this.g, 10);
    this.b = parseInt(this.b, 10);
    this.a = parseFloat(this.a);

    this.getR = function () {
        return this.r;
    };
    this.getG = function () {
        return this.g;
    };
    this.getB = function () {
        return this.b;
    };
    this.getA = function () {
        return this.a;
    };
    this.getRGBCss = function () {
        return 'rgb(' + this.r + ',' + this.g + ',' + this.b + ')';
    };
    this.getRGBACss = function () {
        return 'rgba(' + this.r + ',' + this.g + ',' + this.b + ',' + this.a + ')';
    };
    this.getHEXCss = function () {
        return "#" + ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b).toString(16).slice(1);
    };
    this.getRGB = function () {
        return '' + this.r + ',' + this.g + ',' + this.b + '';
    };
    this.getRGBA = function () {
        return '' + this.r + ',' + this.g + ',' + this.b + ',' + this.a + '';
    };
    this.getHEX = function () {
        return "" + ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b).toString(16).slice(1);
    };

    this.addDeltaR = function (r) {
        this.r = this.r + r;
        if (this.r > 255) this.r = 255;
        if (this.r < 0) this.r = 0;
        return this;
    };
    this.addDeltaG = function (g) {
        this.g = this.g + g;
        if (this.g > 255) this.g = 255;
        if (this.g < 0) this.g = 0;
        return this;
    };
    this.addDeltaB = function (b) {
        this.b = this.b + b;
        if (this.b > 255) this.b = 255;
        if (this.b < 0) this.b = 0;
        return this;
    };
    this.addDeltaA = function (a) {
        this.a = this.a + a;
        if (this.a > 1) this.a = 1;
        if (this.a < 0) this.a = 0;
        return this;
    };
    this.addDeltaRGB = function (r, g, b) {
        this.r = this.r + r;
        if (this.r > 255) this.r = 255;
        if (this.r < 0) this.r = 0;
        this.g = this.g + g;
        if (this.g > 255) this.g = 255;
        if (this.g < 0) this.g = 0;
        this.b = this.b + b;
        if (this.b > 255) this.b = 255;
        if (this.b < 0) this.b = 0;
        return this;
    };

    this.addDeltaRGBA = function (r, g, b, a) {
        this.r = this.r + r;
        if (this.r > 255) this.r = 255;
        if (this.r < 0) this.r = 0;
        this.g = this.g + g;
        if (this.g > 255) this.g = 255;
        if (this.g < 0) this.g = 0;
        this.b = this.b + b;
        if (this.b > 255) this.b = 255;
        if (this.b < 0) this.b = 0;
        this.a = this.a + a;
        if (this.a > 1) this.a = 1;
        if (this.a < 0) this.a = 0;
        return this;
    };
};

/**
 * Zeigt den Loader an
 * @param r
 * @param g
 * @param b
 */
hx.displayLoader = function (r, g, b) {
    if($(".js-hx_loader").length == 0){
        if (typeof r === "undefined" || isNaN(r)){
            r = 224;
            if(typeof hxLoaderColorDefaultR !== "undefined") r = hxLoaderColorDefaultR;
        }
        if (typeof g === "undefined" || isNaN(g)){
            g = 224;
            if(typeof hxLoaderColorDefaultG !== "undefined") g = hxLoaderColorDefaultG;
        }
        if (typeof b === "undefined" || isNaN(b)){
            b = 224;
            if(typeof hxLoaderColorDefaultB !== "undefined") b = hxLoaderColorDefaultB;
        }
        $loaderOverlay = $("<div>").addClass("js-hx_loader hx_loader_overlay").attr("id", name);
        $loaderCon = $("<div>").addClass("hx_loader_con");
        $loader = $("<svg class=\"hx_loader\" viewBox=\"0 0 24 24\">\n" +
            "  <circle class=\"hx_loader_value\" style='stroke: rgb(" + r + "," + g + "," + b + ");' cx=\"12\" cy=\"12\" r=\"10\" />\n" +
            "</svg>");
        $loader = $("<div>").addClass("hx_loader");
        $loader.css("border-left-color", "rgb(" + r + "," + g + "," + b + ")");
        $loaderOverlay.append($loaderCon.append($loader))
        $("body").append($loaderOverlay)
    }
};

/**
 * Zeigt den Loader in einem einzlenen DOM-Element an
 * @param $element jQuery object, DOM-Element muss position: relative oder absolut haben
 * @param zIndex
 * @returns {boolean}
 */
hx.displayLoaderOnElement = function($element, zIndex){
    if($element.find(".js-hx_loader").length > 0) return false;
    if(zIndex === undefined) zIndex = 2000;
    if (typeof r === "undefined" || isNaN(r)){
        r = 224;
        if(typeof hxLoaderColorDefaultR !== "undefined") r = hxLoaderColorDefaultR;
    }
    if (typeof g === "undefined" || isNaN(g)){
        g = 224;
        if(typeof hxLoaderColorDefaultG !== "undefined") g = hxLoaderColorDefaultG;
    }
    if (typeof b === "undefined" || isNaN(b)){
        b = 224;
        if(typeof hxLoaderColorDefaultB !== "undefined") b = hxLoaderColorDefaultB;
    }
    $loaderOverlay = $("<div>").addClass("js-hx_loader hx_loader_overlay").attr("id", name).css("position", "absolute").css("z-index", zIndex);
    $loaderCon = $("<div>").addClass("hx_loader_con");
    $loader = $("<svg class=\"hx_loader\" viewBox=\"0 0 24 24\">\n" +
        "  <circle class=\"hx_loader_value\" style='stroke: rgb(" + r + "," + g + "," + b + ");' cx=\"12\" cy=\"12\" r=\"10\" />\n" +
        "</svg>");
    $loader = $("<div>").addClass("hx_loader");
    $loader.css("border-left-color", "rgb(" + r + "," + g + "," + b + ")");
    $loaderOverlay.append($loaderCon.append($loader))
    $element.append($loaderOverlay)
}

/**
 * Entfernt den Loader
 */
hx.killLoader = function () {
    $(".js-hx_loader").remove()
};

/**
 * Entfernt den Loader aus einem DOM-Element
 * @param $element
 */
hx.killLoaderOnElement = function ($element) {
    $element.find(".js-hx_loader").remove()
};

/*
 Backspace-Taste im Dokument abfangen:
 Zurueckspringen auf vorherige Seite verhindern
 */
hx.onPageReady(function () {
    hx.eventAddCall(document.body, "keydown", function (e) {
        if (!e) e = window.event;
        if (!e.srcElement) e.srcElement = e.target;
        if (hx.value_in(e.srcElement.tagName.toUpperCase(), "INPUT", "TEXTAREA", "DIV")) {
            // alles ok, nix tun
        } else {
            if (e.keyCode == 8) { // Backspace abfangen
                e.preventDefault();
            }
        }
    });
});

$(document).on('click', 'ul.hxmenu li', function (event) {
    var linkObj = $(this).find('a').first();
    var nextObj = linkObj.next().first();
    var href = linkObj.prop('href');

    if (!nextObj || !linkObj || typeof href === "undefined" || !hx.startsWith(href, 'javascript')) {
        event.stopPropagation();
        return;
    }

    if ($(nextObj).prop('tagName') == 'UL') {
        if ($(nextObj).hasClass('hidden')) {
            $(nextObj).slideDown('fast', function () {
                $(nextObj).removeClass('hidden');
            });
        } else {
            $(nextObj).slideUp('fast', function () {
                $(nextObj).addClass('hidden');

            });
        }
    }

    event.stopPropagation();
});

/**
 * Absolute Position eines DOM-Objekts von links im Fenster auslesen
 * @param {object} o
 * @return {number}
 */
hx.getAbsoluteObjectPosLeft = function (o) {
    var l = o.offsetLeft;
    if (o.offsetParent) l = l + hx.getAbsoluteObjectPosLeft(o.offsetParent);
    return l;
};

/**
 * Absolute Position eines DOM-Objekts von oben im Fenster auslesen
 * @param {object} o
 * @return {number}
 */
hx.getAbsoluteObjectPosTop = function (o) {
    var t = o.offsetTop;
    if (o.offsetParent) t = t + hx.getAbsoluteObjectPosTop(o.offsetParent);
    return t;
};

/**
 * Absolute Position eines DOM-Objekts von oben im Fenster auslesen
 * @param {object} o
 * @return {object}
 */
hx.getAbsoluteObjectPosArray = function (o) {
    var t = 0;
    var t2 = 0;
    while (o) {
        t = t + o.offsetTop;
        t2 = t2 + o.offsetLeft;
        o = o.offsetParent;
    }
    var pos = new Object();
    pos.top = t;
    pos.left = t2;
    return pos;
};