/**
 * Testet ob Audio-Objekt existiert. Somit trifft es erst ab IE9 zu
 * @return boolean
 * @deprecated
 */
hx.isFastBrowser = function () {
    if (typeof(Audio) != 'undefined') {
        return true;
    }
    return false;
};

/**
 * Geht alle Elemente eines Arrays/Objekts durch und fuehrt Funktion auf diese aus
 * z.B. hx.foreach(meinDomArray,function(elem,key){
 *          elem.style.outline='none';
 *      });
 * In der uebergebenen Funktion:
 * return true => wie ein continue
 * return false => wie ein break
 * @deprecated
 * @see JQuery.each()
 */
hx.foreach = function (arr, func) {
    if (hx.is_numeric(arr.length)) {
        for (var i = 0; i < arr.length; i++) {
            var retVal = func(arr[i], i);
            if (retVal === false) break;
            if (retVal === true) continue;
        }
    } else {
        for (var i in arr) {
            var retVal = func(arr[i], i);
            if (retVal === false) break;
            if (retVal === true) continue;
        }

    }
};

/**
 *
 * @param obj       - DOM Objekt
 * @param classname - ClassName der hinzugefuegt wird
 * @deprecated
 * @see JQuery.addClass()
 */
hx.classNameAdd = function (obj, classname) {
    var isOldBrowser = false;
    var oldClassname = obj.getAttribute('class');
    if (oldClassname === null) {
        oldClassname = obj.className;
        if (oldClassname == null) {
            oldClassname = "";
        } else {
            isOldBrowser = true;
        }
    } else {
        oldClassname = " " + oldClassname + " ";
    }
    if (oldClassname.indexOf(' ' + classname + ' ') >= 0) return;
    if (isOldBrowser) {
        obj.className = hx.trim(hx.trim(oldClassname) + ' ' + classname);
    } else {
        obj.setAttribute('class', hx.trim(hx.trim(oldClassname) + ' ' + classname));
    }
};

/**
 *
 * @param obj       - DOM Objekt
 * @param classname - ClassName der entfernt wird
 * @deprecated
 * @see JQuery.removeClass()
 */
hx.classNameRemove = function (obj, classname) {
    var isOldBrowser = false;
    var oldClassname = obj.getAttribute('class');
    if (oldClassname === null) {
        oldClassname = obj.className;
        if (oldClassname == null) {
            oldClassname = "";
        } else {
            oldClassname = " " + oldClassname + " ";
            isOldBrowser = true;
        }
    } else {
        oldClassname = " " + oldClassname + " ";
    }
    if (oldClassname.indexOf(' ' + classname + ' ') >= 0) {
        if (isOldBrowser) {
            obj.className = hx.trim(hx.replace(oldClassname, ' ' + classname + ' ', ' '));
        } else {
            obj.setAttribute('class', hx.trim(hx.replace(oldClassname, ' ' + classname + ' ', ' ')));
        }
    }
};

/**
 *
 * @param obj       - DOM Objekt
 * @param classname - ClassName der gesucht ist
 * @deprecated
 * @see JQuery.hasClass()
 */
hx.classNameExists = function (obj, classname) {
    var oldClassname = obj.getAttribute('class');

    if (oldClassname === null) {
        oldClassname = obj.className;
        if (oldClassname == null) oldClassname = "";
    } else {
        oldClassname = hx.trim(oldClassname);
    }
    var splitClassNames = hx.explode(" ", oldClassname);
    return hx.in_array(splitClassNames, classname);
};

/**
 * Wenn Seite geladen, dann fuehre FN aus.
 * @param {function} fn  auszufuehrende Funktion
 * @param {string} readystate "complete" (seite komplett geladen) oder "interactive" (dom bereit)
 * @deprecated
 * @see JQuery
 */
hx.onPageReady = function (fn, readystate) {
    if (!readystate) readystate = "complete";
    var readyStateCheckInterval = setInterval(function () {
        if (document.readyState === readystate) {
            try {
                fn();
            } catch (e) {
                clearInterval(readyStateCheckInterval);
                throw "Error in 'hx.onPageReady': " + e.message;
            }
            clearInterval(readyStateCheckInterval);
        }
    }, 10);
};

/**
 * Alle Callbackfunktionen entfernen zu einem Event
 * @param obj
 * @param type
 * @deprecated
 * @see JQuery
 */
hx.eventRemoveAllCalls = function (obj, type) {
    if (hx.is_object(obj.hxEventFnList)) {
        hx.foreach(obj.hxEventFnList, function (evFn) {
            hx.eventRemoveCall(obj, type, evFn);
        });
    }
};

/**
 * Auf eine Gruppe von Elementen ein oder mehrere Events registrieren. Auch neu erstellte Elemente reagieren auf dieses Event.
 * @param cssSelector
 * @param types
 * @param fn
 * @deprecated
 * @see JQuery
 */
hx.eventAddCallLive = function (cssSelector, types, fn) {
    hx.eventAddCallLive.id = hx.cint(hx.eventAddCallLive.id) + 1;
    var func = "" +
        "if(!e.target) e.target = e.srcElement;" +
        "var el = e.target,found;" +
        "while (el && !(found = hx.in_array( hx.objByCssSelector(\"" + cssSelector + "\"),el ))) {" +
        "el = el.parentElement;" +
        "}" +
        "if (found) {" +
        "(" + (fn) + ").call(el, e);" +
        "}" +
        "/*" + hx.eventAddCallLive.id + "*/" +
        "";
    hx.eventAddCall(document.body, types, new Function("e", func));
};

/**
 *
 * @param obj
 * @param type
 * @deprecated
 * @see JQuery.trigger
 */
hx.eventFire = function (obj, type) {
    if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent(type, false, true);
        obj.dispatchEvent(evt);
    } else {
        obj.fireEvent("on" + type);
    }
};

/**
 * @deprecated
 */
hx.debugs = function () {
    try {
        console.log.apply(console, arguments);
    } catch (e) {  }
};

/**
 * Liefert die Scroll-Postion im Browser-Window
 * @returns {Object}
 * @deprecated
 * @see JQuery
 */
hx.getScrollPosWindow = function () {
    var x = 0, y = 0;
    if (typeof( window.pageYOffset ) == 'number') {
        //Netscape compliant
        y = window.pageYOffset;
        x = window.pageXOffset;
    } else if (document.body && ( document.body.scrollLeft || document.body.scrollTop )) {
        //DOM compliant
        y = document.body.scrollTop;
        x = document.body.scrollLeft;
    } else if (document.documentElement &&
        ( document.documentElement.scrollLeft || document.documentElement.scrollTop )) {
        //IE6 standards compliant mode
        y = document.documentElement.scrollTop;
        x = document.documentElement.scrollLeft;
    }
    var obj = new Object();
    obj.left = x;
    obj.top = y;
    return obj;
};

