/**
 * Prueft, ob eine Variable ein Objekt ist
 * @param value
 * @returns {boolean}
 */
hx.is_object = function (value) {
    if (Object.prototype.toString.call(value) === '[object Object]') return true;

    return false;
};

hx.isFunction = function(funct){
    return (typeof funct === 'function')
}

/**
 * SVG Browserunterstuetzung pruefen
 * @return false falls nicht unterstuetzt
 */
hx.isSVGSupported = function () {
    return true;
};

