class HXList {

    listName = "";
    formName = "";
    colResizeTimeOutId = null;
    listVars = null;

    constructor(listName = null) {
        this.listName = listName;
        this.formName = listName;

        var tmpVar = window["\$hxListVar"+this.listName];
        this.listVars = {
            root          : (tmpVar.hasOwnProperty("root")===true)           ? tmpVar.root : "",
            dictAskdelMsg : (tmpVar.hasOwnProperty("dictAskdelMsg")===true)  ? tmpVar.dictAskdelMsg : "",
        };

    }

    isTouchDevice = function () {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }

    /**
     *
     * @param {integer} id
     * @param {string} urlParam
     */
    rowDelete(id, urlParam) {
        var $imgdel = $("form[name='"+this.listName+"'] img[name='imd"+id+"']");

        $imgdel.attr("src",this.listVars.root+"libhx/img/list/askdel.png");
        if (confirm(this.listVars.dictAskdelMsg) === true) {
            if (urlParam !== '') {
                var actionStr = document.forms[this.formName].action;
                if (actionStr.indexOf('?') < 0) {
                    actionStr+= '?' + urlParam;
                } else {
                    actionStr+= '&' + urlParam;
                }
                document.forms[this.formName].action = actionStr;
            }
            var scrollpos = hx.getScrollPosWindow();
            document.forms[this.formName].listScrollPosTop.value = scrollpos.top;
            document.forms[this.formName].listScrollPosLeft.value = scrollpos.left;
            document.forms[this.formName].id_del.value = id;
            document.forms[this.formName].submit();
        }
        $imgdel.attr("src",this.listVars.root+"libhx/img/list/del.png");
    }

    /**
     * Zeile markieren bzw. Markierung entfernen
     * @param trObj
     */
    rowToggleHighlight = function (trObj) {
        $(trObj).toggleClass('hxlistrowhighlight');
    };


    colToggleMultiline(visibleColNumber, colRowDelta, colHeadDelta, elem) {
        var toggleMultilineElement = $("form[name='"+this.listName+"'] .hxlistrowhead td:nth-child("+(hx.cint(visibleColNumber)+hx.cint(colHeadDelta))+") > i.toggleMultiline");
        var toggleStateClosed = true;

        //Spaltenkopf wurde geklickt
        if (elem===null || elem===undefined) {
            var colObj = $("form[name='"+this.listName+"'] .hxlistrow td:nth-child(" + (hx.cint(visibleColNumber)+hx.cint(colRowDelta)) + ")");
            if (toggleMultilineElement.hasClass("icon-menu-close")) {
                toggleStateClosed = false;
                toggleMultilineElement.attr('class', 'toggleMultiline icon icon-menu-open');
            } else {
                toggleMultilineElement.attr('class', 'toggleMultiline icon icon-menu-close');
            }
            //einzelne Zelle wurde geklickt
        } else {
            var colObj = $(elem);
            if (colObj.hasClass('toggleMultilineOpen')) toggleStateClosed = false;
        }

        //Ganze Spalte umschalten
        if (toggleStateClosed===false) {
            colObj.removeClass('toggleMultilineOpen');
            colObj.addClass('toggleMultilineClosed');
        } else {
            colObj.removeClass('toggleMultilineClosed');
            colObj.addClass('toggleMultilineOpen');
        }

    }

    /**
     *
     * @param {integer} sort
     */
    colSort(sort) {
        document.forms[this.formName].sort.value = sort;
        var scrollpos = hx.getScrollPosWindow();
        document.forms[this.formName].listScrollPosTop.value = scrollpos.top;
        document.forms[this.formName].listScrollPosLeft.value = scrollpos.left;
        document.forms[this.formName].submit();
    }

    /**
     *
     * @param {integer} pageNr
     */
    pageGoto(pageNr) {
        document.forms[this.formName].page.value = pageNr;
        document.forms[this.formName].submit();
    }

    settingsReset() {
        do {
            var moved = false;
            $("form[name="+this.formName+"] .listsettings_ul").children().each(function (idx, elem) {
                var colNbr = $(elem).data("columnpos_default");
                var pevColNbr = $(elem).prev().data("columnpos_default")
                $(elem).find("input:checkbox").prop("checked", true);
                if (pevColNbr !== undefined && colNbr !== undefined && hx.cint(colNbr) < hx.cint(pevColNbr)) {
                    $(elem).insertBefore($(elem).prev());
                    moved = true;
                }
            });
        } while (moved);
    }

    settingsSave() {
        //die aktuellen Settings sollen gepeichert werden
        document.forms[this.formName].listSettings.value = 1;
        document.forms[this.formName].submit(); //Formular absenden - Filter unveraendert lassen
    };

    /**
     *
     * @param {integer} action 1..do filter, -1..reset filter
     */
    filter(action) {
        document.forms[this.formName].filter.value=action;
        document.forms[this.formName].page.value=1;
        var scrollpos = hx.getScrollPosWindow();
        document.forms[this.formName].listScrollPosTop.value = scrollpos.top;
        document.forms[this.formName].listScrollPosLeft.value = scrollpos.left;
        if (document.activeElement && document.activeElement.name) document.forms[this.formName].listActiveElement.value = document.activeElement.name;
        document.forms[this.formName].submit();
    }

    //Spaltenbreite aendern: Breite in Session speichern
    filterResizeSave(elem) {
        var width    = hx.cint(elem.style.width);
        var minWidth = hx.cint(elem.style.minWidth);
        var colIndex = elem.getAttribute("data-index");

        if (hx.cint(width)>0 && hx.cint(minWidth)>0 && colIndex.length>0 && this.listName.length>0) {
            if (width <= minWidth) { //Mindestbreite einhalten - resize Event liefert leider breite bei der Maus losgelassen wurde
                width = null; //Defaultbreite 
            }

            $.ajax({
                url: $hxVar.root+'libhx/ajax/hxlist/ajax_hxlist.php',
                type: "POST",
                async: true,
                data:{
                    listname:this.listName,
                    colindex:colIndex,
                    colwidth:width}
            });
        }
    }

    filterMultiSelectOptionsDisplay(select,display=true) {
        var obj = document.getElementById(select.name + '_options');

        if (display) {
            obj.style.display = '';
            obj.style.top = '0px';
            obj.style.left = '0px';
            obj.focus();
        } else {
            var multiselectHidden = $("#" + select.name + "_hidden");
            var oldval = multiselectHidden.val();
            this.filterMultiSelectTextUpdate(select.name);
            //beim verlassen sofort filtern - wenn Filteraenderungen erfolgt sind
            if ((this.isTouchDevice() || obj.getAttribute('data-filteronchange') === '1') && oldval + '' !== ''+multiselectHidden.val()) {
                window["filtern_" + this.listName](1);
            }
            var oldDisplay = obj.style.display;
            obj.style.display = 'none';
            //Aktuelles li.active wird nicht entfernt, damit beim wieder oeffnen des Multiselects dort wieder fortgesetzt werden kann

            //Focus auf Select-Element setzen, damit danach mit "Enter" Filterung ausgel�st werden kann
            //oldDisplay: Klick ausserhalb vom Multiselect schliesst alle ->  Focus nur auf jenes setzten, dass zuvor offen war - Browser scrollt autom. zum Feld
            if (oldDisplay==='') {
                select.focus();
            }
        }
    }

    //MultiselectFilter: Anzeigetext und hidden-Element aktualisieren
    filterMultiSelectTextUpdate(fieldName) {
        var str = "";
        var checkedCount = 0;
        var field_hidden = document[this.formName][fieldName + '_hidden']; //hidden-Element des MuliSelectFilters
        var field = document[this.formName][fieldName]; //select-Element des MuliSelectFilters


        field_hidden.value = "";
        $("#" + fieldName + "_options input:checkbox").each((idx, elem) => {
            if (elem.checked) {
                str += ', ' + hx.strip_tags(elem.parentNode.innerHTML);
                checkedCount++;
                if (idx > 0) field_hidden.value += elem.value + ",";
            }
        });
        field_hidden.value = hx.trim(field_hidden.value, ", ");
        field.title = hx.trim(str, ', ');

        if (checkedCount === 1) {
            field[0].innerHTML = hx.trim(str, ', ');
        } else {
            field[0].innerHTML = "&#9745; "+checkedCount;
        }
    }


    filterMultiSelectEventsInit(select) {
        var obj = document.getElementById(select.name + '_options');

        //Ausserhalb div.multiselect_options klicken - dieses schliessen
        $(document).on("mousedown", (event) => {
            this.filterMultiSelectOptionsDisplay(select,false);
        });

        //div.multiselect_options schliessen verhindern beim Anklicken
        $(obj).on("mousedown", (event) => {
            event.stopPropagation();
        });

        //div.multiselect_options schliessen beim Klicken auf Close-Icon
        //Gleichzeitig Filtern ausloesen, wenn dies die Einstellung so wuenscht
        //Auf Touchgeraeten immer Filtern ausloesen (Grund: Es wird auf Touch-Geraeten keine Tastatur eingeblendet)
        $(obj).find("div.close").on("click",(event) => {
            event.stopPropagation(); //Event auf darunterliegendes li nicht weitergeben
            this.filterMultiSelectOptionsDisplay(select,false);
        });

        //Scrollen mit Tasten verhindern
        $(obj).on("keydown", (event) => {
            if (event.key === "ArrowDown" || event.key === "ArrowUp" || event.key === " ") {
                event.preventDefault();
                return false;
            }
        });

        //div.multiselect_options mit Tasten durchnavigieren
        $(obj).on("keyup", (event) => {
            var $optActive = $(obj).find("li.active");

            //Escape, Enter, Tab: Multiselect schliessen
            if (event.key === "Enter" || event.key === "Escape" || event.key === "Tab") {
                this.filterMultiSelectOptionsDisplay(select,false);

            //ArrowDown: Naechste Option auswaehlen
            } else if (event.key === "ArrowDown") {
                if ($optActive.length===0) {
                    $(obj).find("li").first().addClass("active");
                } else {
                    if (!$optActive.is(':last-child') && $optActive.next()) {
                        $optActive.removeClass("active");
                        $optActive.next().addClass("active");
                    }
                }
            //ArrowUp: Vorherige Option auswaehlen
            } else if (event.key === "ArrowUp") {
                if ($optActive.length===0) {
                    $(obj).find("li").first().addClass("active");
                } else {
                    if (!$optActive.is(':first-child') && $optActive.prev()) {
                        $optActive.removeClass("active");
                        $optActive.prev().addClass("active");
                    }
                }
            //Space: Option auswaehlen
            } else if (event.key === " ") {
                if ($optActive.length) $optActive.trigger("click");
            }

            if ($optActive.length) {
                var t = $optActive[0]; //native DOM-Element
                var p = t.parentNode.parentNode;
                var grenzeOben = p.scrollTop;
                var grenzeUnten = p.offsetHeight + p.scrollTop;

                if ((t.offsetTop - t.offsetHeight) < grenzeOben && event.key === "ArrowUp") {
                    p.scrollTop = t.offsetTop - t.offsetHeight;
                } else if ((t.offsetTop + t.offsetHeight * 2) > grenzeUnten && event.key === "ArrowDown") {
                    p.scrollTop = t.offsetTop + t.offsetHeight * 2 - p.offsetHeight;
                }
            }
            event.preventDefault();
            return false;
        });


        // Option Checkbox-Text Click Handling
        $(obj).find('li').on("click", (event) => {
            $(event.target.getElementsByTagName('input')[0]).trigger("click");
        });

        // Option Checkbox click Handling
        $(obj).find('input').on("click", (event) => {

            $(obj).find('li').removeClass("active");
            $(event.currentTarget.parentNode).addClass("active");

            if ($(event.currentTarget).hasClass("asterisk")) {
                if (event.currentTarget.checked) {
                    $(obj).find("input:not(.asterisk)").prop("checked", false);
                } else {
                    $(obj).find("input:not(.asterisk)").prop("checked", true);
                }
            } else {
                if (event.currentTarget.checked) {
                    $(obj).find("input.asterisk").prop("checked", false);
                } else {
                    if ($(obj).find("input:checked").length===0) {
                        $(obj).find("input.asterisk").prop("checked", true);
                    }
                }
            }
            event.stopPropagation(); //Click nicht an <li> weitergeben
        });
    }

    /**
     *
     * @param {string} activeElemName
     * @param {integer} scrollPosLeft
     * @param {integer} scrollPosTop
     */
    displayInit(activeElemName,scrollPosLeft,scrollPosTop) {

        var $listForm = $("form[name='"+this.listName+"']");

        //Focus auf Filterfeld setzten
        if (document.forms[this.formName].elements[activeElemName]) document.forms[this.formName].elements[activeElemName].focus();
        //Scrollbars auf vorherige Position setzen
        if (hx.cint(scrollPosTop)>0 || hx.cint(scrollPosLeft)>0) window.scrollTo(scrollPosLeft,scrollPosTop);

        //Filtern mit Enter ausloesen wenn Focus auf Filterfeld steht - hxfimultiselect hat eigenen Event Handler
        $listForm.find(".hxfitext, .hxfinumber, .hxfibooleanselect, .hxfiselect, .hxfidate,.hxfimultiselect").on("keypress", (event) => {
            if (event.key === "Enter") window["filtern_" + this.listName](1);
        });

        //Auf Touchgeraeten nach dem auswaehlen von Select-Filtern das Filtern ausloesen (Grund: Es wird auf Touch-Geraeten keine Tastatur eingeblendet)
        $listForm.find(".hxfiselect, .hxfibooleanselect").on("change", (event) => {
            if (this.isTouchDevice()) window["filtern_" + this.listName](1);
        });

        //MultiselectFilter oeffnen

        //document.querySelector(".hxfimultiselect").addEventListener("mousedown", (event) => {
        $listForm.find(".hxfimultiselect").on("mousedown",  (event) =>  {
            this.filterMultiSelectOptionsDisplay(event.currentTarget,true);
            event.preventDefault();
            event.stopPropagation(); //Verhindert sofortiges schliessen durch ausserhalb div.multiselect_options klicken
            return false;
        })

        $listForm.find(".hxfimultiselect").on("keydown",  (event) =>  {
            if (event.key===" " || event.key==="ArrowDown") {
                this.filterMultiSelectOptionsDisplay(event.currentTarget,true);
                event.preventDefault();
                return false;
            }
        });

        //MultiselectFilter initialisieren
        $listForm.find(".hxfimultiselect").each((idx,elem) => {
            this.filterMultiSelectTextUpdate($(elem).attr("name")); //Alle Multiselect-Filter: angezeigten Text aktualisieren
            this.filterMultiSelectEventsInit(elem); //Events der MultiselectFilter einh�ngen
        });


        //Zellen-Tooltips dynamisch erzeugen wenn textbreite groesser spaltenbreite
        $listForm.find(".hxlistrow td:not(.listcelldeletebtn)").on("mouseenter", (event) => {
            var elem = event.currentTarget;
            if (!$(elem)) return;

            var uniqueClassName = "hxlist-tooltip-text";
            var html = $(elem).html();
            var tdWidth = $(elem).width();

            $("body").append("<span style=\"visibility:hidden;\" class=\"" + uniqueClassName + "\">" + html + "</span>");
            var tooltipObject = $("." + uniqueClassName);
            var textWidth = tooltipObject.width();
            tooltipObject.remove();

            if (textWidth > tdWidth) $(elem).attr("title", $(elem).text());
            else $(elem).attr("data-notooltip", "1");
        });


        //Zeilenmarkierung via Mausklick on/off
        $listForm.find(".hxlistrow").on("click", (event) => {
            this.rowToggleHighlight($(event.currentTarget).get(0));
        });

        //toggleMultiline je Spalte initialisieren - Alle Spalten (listcolumnheadline) durchgehen
        $listForm.find('.hxlistrowhead td.listcolumnheadline').each((idx,elem) => {
            //Existiert im Spaltenkopf ein toggleMulitline Icon, dann...
            if ($(elem).children('i.toggleMultiline').length) {
                var colRowDelta = $(elem).children('i.toggleMultiline').attr("data-togglemultiline-colrowdelta");
                var colHeadDelta = $(elem).children('i.toggleMultiline').attr("data-togglemultiline-colheaddelta");
                var rowCell = $("form[name='"+this.listName+"'] .hxlistrow td:nth-child("+(hx.cint(idx)+hx.cint(colRowDelta))+")");
                rowCell.addClass('toggleMultilineClosed');  //Zoom-in Icon in allen Zellen der Spalte

                rowCell.on('click', (event) => { //Click Event registrieren
                    this.colToggleMultiline(idx,colRowDelta,colHeadDelta,event.currentTarget);
                });
            }
        });

        //ListSetting-Overlays: Ausserhalb klicken - dieses schliessen
        $(document).on("click", (event) => {
            $("form[name='"+this.listName+"'] .js-hx_listsettings").hide();
        });

        //ListSetting-Overlay: Schliessen verhindern beim Anklicken
        $("form[name='"+this.listName+"'] .js-hx_listsettings").on("click", (event) => {
            event.stopPropagation();
        });

        //Listsetting-Overlay: Zahnrad-Icon anklicken - Overlay oeffnen/schliessen
        $("form[name='"+this.listName+"'] .js-hx_listsettings_toggler").on("click", (event) => {
            event.stopPropagation(); //stop bubbling to body
            event.preventDefault();
            var $hxListsettings = $("form[name='"+this.listName+"'] .js-hx_listsettings");

            $hxListsettings.toggle(); //show/hide Overlay

            //Ist Listentabelle weniger hoch als das Settings-Overlay, dann Overlay nach unten oeffnen
            if ($(".hxlist."+this.listName).height() < $hxListsettings.height() + 100) {
                $hxListsettings.addClass("listsettings-down")
            } else {
                $hxListsettings.removeClass("listsettings-down")
            }
        });

        //Listsetting-Overlay: Spaltensortierung via Drag&Drop ermoeglichen
        $("form[name='"+this.listName+"'] .listsettings_ul").sortable().disableSelection(); //JQery UI

        //Auf Touchgeraeten gibt es kein Mouseover: nofilter-Button zeigt beim
        //ersten Touch den filter-Button an, fuehrt dann die eigentliche Aktion aus
        //In Safari und Edge ist overflow=visible bereits Eventausfuehrung, bei Chrome noch nicht
        $("form[name='"+this.listName+"'] div.listcellfilterbtnbox img").on("touchstart", () => {
            if (!$("form[name='"+this.listName+"'] img.listbtn_filter").is(":visible") && $("form[name='"+this.listName+"'] .listbtn_phonefilter").length===0) {
                $("form[name='"+this.listName+"'] img.listbtn_filter").css("left", "18px").show();
                return false;
            } else {
                return true;
            }
        });

        //Spaltenbreite aendern: via JQueryUI aktivieren
        $("form[name='"+this.listName+"'] .hxlist_colresize.active").resizable({
            handles:'e' //nur nach rechtes resizable machen
        });

        //Spaltenbreite aendern
        $listForm.on("resize", ".hxlist_colresize.active", (event) =>{
            clearTimeout(this.colResizeTimeOutId);
            this.colResizeTimeOutId = setTimeout(() => {this.filterResizeSave(event.currentTarget)}, 500);
        });

        //Spaltenbreite aendern: Bei Doppelklick auf doppelte oder Ursprungsbreite springen
        $listForm.on("dblclick", ".ui-resizable-handle", (event) => {
            var $parentBox = $(event.currentTarget).parent(".hxlist_colresize.active");
            if ($parentBox.length>0) {
                var withCurrent = $parentBox.css("width");
                var withMin = $parentBox.css("min-width");
                var width = withMin; //Default: Breite auf Mindestbreite setzen
                if (withCurrent === withMin) width = (hx.cint(withCurrent)*2)+"px"; //Breite verdoppeln
                $parentBox.css("width",width);
                this.filterResizeSave($parentBox[0]); //[0] ... = native DOM Element (non JQuery Element)
            }
        });

    }

}
