/**
 * @deprecated
 */
function getKW(datum) {
    var jahr = datum.getFullYear();
    var jh = jahr + 1;
    var kalwo = _kaldiff(datum, jh);
    while (kalwo < 1) {
        jh--;
        kalwo = _kaldiff(datum, jh);
    }
    return kalwo;
}

/**
 * @deprecated
 */
function _kaldiff(datum, jahr) {
    var d4j = new Date(jahr, 0, 4);
    var wt4j = (d4j.getDay() + 6) % 7;
    return Math.floor(1.05 + (datum.getTime() - d4j.getTime()) / 6048e5 + wt4j / 7);
}


/**
 * @deprecated
 */
function getDatumFromKW(kw, jahr) {
    var datum = new Date(jahr, 6, 1);
    while (getKW(datum) > kw && datum.getFullYear() == jahr) datum = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() - 1);
    while (getKW(datum) < kw && datum.getFullYear() == jahr) datum = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() + 1);
    // Montag
    while (getKW(datum) == kw) datum = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() - 1);
    return new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() + 1);
}

/**
 * @deprecated
 */
function dateDiff(strDate1, strDate2) {
    datDate1 = Date.parse(strDate1);
    datDate2 = Date.parse(strDate2);
    return ((datDate2 - datDate1) / (24 * 60 * 60 * 1000));
}

/**
 * @deprecated
 */
function format00(s) {
    t = "" + s;
    if (t.length < 2) t = "0" + t;
    if (t.length < 2) t = "0" + t;
    return t;
}

/**
 * @deprecated
 */
function FormatPreis(wert, nachkomma) {
    if (isNaN(wert) || wert + "" == "") return "";
    zahl = parseFloat(wert);
    var n = 0;
    zahl = Math.round(zahl * Math.pow(10, nachkomma)) / Math.pow(10, nachkomma);
    if (zahl.toFixed) zahl = zahl.toFixed(nachkomma);
    var t = "" + zahl;
    var z = "";
    for (n = 0; n < t.length; n++) {
        if (t.substr(n, 1) == ".") {
            z = z + ",";
        } else {
            z = z + t.substr(n, 1);
        }
    }
    var pos = z.indexOf(",");
    if (pos < 0) pos = z.length;
    var erg = z.substr(pos, 100);
    var c = 0;
    for (n = pos - 1; n >= 0; n--) {
        if (c == 3) {
            erg = "." + erg;
            c = 1;
        } else {
            c++;
        }
        erg = z.substr(n, 1) + erg;
    }
    return erg;
}


/**
 * @deprecated
 */
function formatPreis(wert, nachkomma) {
    if (isNaN(wert) || wert + "" == "") return "";
    zahl = parseFloat(wert);
    var n = 0;
    zahl = Math.round(zahl * Math.pow(10, nachkomma)) / Math.pow(10, nachkomma);
    if (zahl.toFixed) zahl = zahl.toFixed(nachkomma);
    var t = "" + zahl;
    var z = "";
    for (n = 0; n < t.length; n++) {
        if (t.substr(n, 1) == ".") {
            z = z + ",";
        } else {
            z = z + t.substr(n, 1);
        }
    }
    var pos = z.indexOf(",");
    if (pos < 0) pos = z.length;
    var erg = z.substr(pos, 100);
    var c = 0;
    for (n = pos - 1; n >= 0; n--) {
        if (c == 3) {
            erg = "." + erg;
            c = 1;
        } else {
            c++;
        }
        erg = z.substr(n, 1) + erg;
    }
    return erg;
}

/**
 * @deprecated
 */
function replace(textstr, findstr, repstr) {
    var t = '', f = '', r = '', n = 0, npos = 0;
    t = '' + textstr;
    f = '' + findstr;
    r = '' + repstr;
    if ((f != "") && (f != r)) {
        npos = t.indexOf(f);
        while (npos >= 0) {
            t = t.substr(0, npos) + r + t.substr(npos + f.length);
            n = npos + r.length;
            npos = t.substr(n).indexOf(f);
            if (npos >= 0) npos = npos + n;
        }
    }
    return t;
}

/**
 * @deprecated
 */
function FormatKomma(wert, komma) {
    var pos, n, vor = "", nach = "", erg = "", content = "";
    wert = wert * Math.pow(10, komma);
    wert = Math.round(wert);
    wert = wert / Math.pow(10, komma);
    cont = "" + wert;
    content = "";
    content = cont.replace(/\./, ",");
    pos = content.indexOf(",");
    if (pos < 0) pos = content.length;
    vor = content.substr(0, pos);
    nach = content.substr(pos + 1, 100);
    if (nach.length < komma) nach = nach + "0";
    if (nach.length < komma) nach = nach + "0";
    for (n = vor.length; n >= 0; n--) {
        erg = String(vor.charAt(n)) + String(erg);
    }
    return String(erg) + "." + String(nach);
}

/**
 * @deprecated
 */
function httpRequest(url, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            http_request.overrideMimeType('text/plain');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");

            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) http_request.onreadystatechange = function () {
        if (http_request.readyState == 4) {
            callbackfunction(http_request.responseText);
        }
    };
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('GET', url, true);
            http_request.send(null);
        } else {
            http_request.open('GET', url, false);
            http_request.send(null);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
}

/**
 * @deprecated
 */
function httpPostRequest(url, postdata, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            // http_request.overrideMimeType('text/plain');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) http_request.onreadystatechange = function () {
        if (http_request.readyState == 4) {
            callbackfunction(http_request.responseText);
        }
    };
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('POST', url, true);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
        } else {
            http_request.open('POST', url, false);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
}

/**
 * @deprecated
 */
function isValidString(str, format) {
    var a = "";
    if (format == 'INT') {
        a = '0123456789';
    }
    else if (format == 'FLOAT') {
        a = '0123456789.';
    }
    else if (format == 'azAZ') {
        a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'azAZ09') {
        a = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'FILENAME') {
        a = '0123456789_-.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else {
        a = format;
    }

    var c = '';
    merker = true;
    for (n = 0; n < str.length; n++) {
        c = str.substr(n, 1);
        if (a.indexOf(c) < 0) {
            merker = false;
            break;
        }
    }
    return merker;
}

/**
 * @deprecated
 */
function FixLen(t, len) {
    while (t.length < len) {
        t = t + " ";
    }
    return t.substr(0, len);
}


function open_popup(url, w, h, winname) {
    var wi = window.open(url, winname, "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,width=" + w + ",height=" + h + ",resizable=yes,dependent=1").focus();
    try {
        wi.focus();
    } catch (e) {
    }
}





