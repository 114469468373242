/**
 * Wie die PHP-Funktion rand()
 * Gibt eine zufaellige Zahl (int) zurueck zwischen "min" und "max"
 *
 * @param {number} min    - kleinster zulaessige Wert
 * @param {number} max    - groe�ter zulaessiger Wert
 *
 * @return {number}
 */
hx.rand = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

hx.ceil = function (value) {
    return Math.ceil(value);
};

hx.floor = function (value) {
    return Math.floor(value);
};

/**
 * Wie die PHP-Funktion round()
 * Kaufmaennisches Runden mit Angabe auf wie viel Nachkommastellen
 *
 * @param {number}        value
 * @param {number}        precision - Anzahl Nachkommastellen
 *
 * @return {number}
 */
hx.round = function (value, precision) {
    if (!precision) precision = 0;
    if (precision < 0) return value;
    value = value * Math.pow(10, precision);
    value = Math.floor(value + 0.5);
    value = value / Math.pow(10, precision);
    return value;
};

/**
 * Prueft, ob eine Variable eine Zahl enthaelt, Dezimaltrennzeichen ist Punkt. Leerstring liefert false
 * @param value
 * @returns {boolean}
 */
hx.is_numeric = function (value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

/**
 * Zaehlt Anzahl eintraege eines Arrays/Objects. Liefert -1 wenn kein Array/Object uebergeben wurde
 * @param obj		Array/Object
 * @return {number}
 */
hx.count = function (obj) {
    var size;
    try {
        size = obj.length;
        if (hx.is_numeric(size)) return obj.length;
    } catch (e) {
    }

    try {
        size = Object.keys(obj).length;
        if (hx.is_numeric(size)) return size;
    } catch (e) {
    }

    try {
        size = 0;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        if (hx.is_numeric(size)) return size;
    } catch (e) {
    }

    return -1;
};

/**
 * Prueft ob Wert (zB aus Inputfeld) ein gueltiger INT Wert ist
 *
 * @param str Deutsch formatierte Zahl
 * @returns {boolean}
 */
hx.text_isint = function (str) {
    if (!str) str = "";
    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");

    if (hx.is_numeric(t) && hx._has_decimalplaces(t) == false) return true;
    return false;
};

/**
 * Prueft ob Wert (zB aus Inputfeld) ein gueltiger FLOAT Wert ist
 *
 * @param str Deutsch formatierte Zahl
 * @returns {boolean}
 */
hx.text_isfloat = function (str) {
    if (!str) str = "";

    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");

    if (hx.is_numeric(t) && hx._has_decimalplaces(t) == true) return true;
    return false;
};

/**
 * Wandelt Wert aus String (zB aus Inputfeld) in  FLOAT Wert um wenn gueltig, sonst NULL
 *
 * @param str
 * @returns {*}
 */
hx.text2float = function (str) {
    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");
    if (!hx.is_numeric(t)) return null;
    return hx.cfloat(t);
};

/**
 * Wandelt Wert aus String (zB aus Inputfeld) in INT Wert um wenn gueltig, sonst null
 *
 * @param {string} str Deutsch formatierte Zahl
 * @return {number}
 */
hx.text2int = function (str) {
    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");
    if (!hx.is_numeric(t)) return null;
    if (hx._has_decimalplaces(t)) return null;
    return hx.cint(t);
};

hx._has_decimalplaces = function (value) {
    value = "" + value;
    if (value.lastIndexOf(".") >= 0 && value.lastIndexOf(".") != value.length - 1) {
        return true;
    }
    return false;
};

/**
 * Wie ein (int)-Cast in PHP
 * Gueltigen Werten werden die Nachkommastellen abgeschnitten
 * und ungueltige Werte werden zum Wert 0
 *
 * @param value
 * @returns {number}
 */
hx.cint = function (value) {
    if (typeof value == 'undefined') return 0;
    if (value === null) return 0;
    if (hx.is_array(value) || hx.is_object(value)) {
        if (hx.count(value) > 0) {
            return 1;
        }
        return 0;
    }
    if (hx.is_string(value)) {
        if (value.charAt(0) == '.') value = "0" + value;
        if (value.charAt(0) == '+') value = value.substr(1);
        for (var i = 0; i < value.length; i++) {
            if (value.charAt(i).search(/[0-9\.\-]/) < 0) {
                value = value.substr(0, i);
                break;
            }
        }
    } else if (value === true || value === false) {
        return Math.abs(value);
    }
    if (hx.is_numeric(value)) {
        return parseInt(value, 10);
    } else {
        return 0;
    }
};

/**
 * Wie ein (float)-Cast in PHP
 * Ungueltige werte werden zum Wert 0
 *
 * @param value
 * @returns {number}
 */
hx.cfloat = function (value) {
    if (typeof value == 'undefined') return 0;
    if (value === null) return 0;
    if (hx.is_array(value) || hx.is_object(value)) {
        if (hx.count(value) > 0) {
            return 1;
        }
        return 0;
    }
    if (hx.is_string(value)) {
        if (value.charAt(0) == '+') value = value.substr(1);
        for (var i = 0; i < value.length; i++) {
            if (value.charAt(i).search(/[0-9\.\-]/) < 0) {
                value = value.substr(0, i);
                break;
            }
        }
    } else if (value === true || value === false) {
        return Math.abs(value);
    }
    if (hx.is_numeric(value)) {
        return parseFloat(value);
    } else {
        return 0;
    }
};

/**
 * Formatiert einen Wert mit fuehrenden Nullen auf 2 Stellen
 * @param {String|number} s
 * @returns {string}
 */
hx.format00 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 2) t = "0" + t;
    if (t.length < 2) t = "0" + t;
    return t;
};

/**
 * Formatiert einen Wert mit fuehrenden Nullen auf 3 Stellen
 * @param {String|number} s
 * @returns {string}
 */
hx.format000 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 3) t = "0" + t;
    if (t.length < 3) t = "0" + t;
    if (t.length < 3) t = "0" + t;
    return t;
};

/**
 * Formatiert einen Wert mit fuehrenden Nullen auf 4 Stellen
 * @param {String|number} s
 * @returns {string}
 */
hx.format0000 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 4) t = "0" + t;
    if (t.length < 4) t = "0" + t;
    if (t.length < 4) t = "0" + t;
    if (t.length < 4) t = "0" + t;
    return t;
};

/**
 * Formatiert einen Wert mit fuehrenden Nullen auf 5 Stellen
 * @param {String|number} s
 * @returns {string}
 */
hx.format00000 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    return t;
};

/**
 * Formatiert eine Zahl ins Deutsche Format (Beistrich statt Punkt und Punkt als Tausendertrennzeichen)
 *
 * @param {string|int|float} wert
 * @param {string|int} nachkomma            - Anzahl der anzuzeigenden Nachkommastellen
 * @return {string}
 */
hx.formatPreis = function (wert, nachkomma) {
    if (nachkomma == undefined) nachkomma = 2;
    if (nachkomma < 0) { // Wenn nachkomma negativ, dann so viele nachkommastellen wie noetig
        var t = ("" + wert).split(".");
        if (t[1]) {
            nachkomma = t[1].length;
        } else {
            nachkomma = 0;
        }
    }
    var zahl = hx.cfloat(wert);
    var negativ = false;
    if (zahl < 0) {
        negativ = true;
        zahl = zahl * -1;
    }
    var n = 0;
    zahl = Math.round(zahl * Math.pow(10, nachkomma)) / Math.pow(10, nachkomma);
    if (zahl.toFixed) zahl = zahl.toFixed(nachkomma);
    var t = "" + zahl;
    var z = "";
    for (n = 0; n < t.length; n++) {
        if (t.substr(n, 1) == ".") {
            z = z + ",";
        } else {
            z = z + t.substr(n, 1);
        }
    }
    var pos = z.indexOf(",");
    if (pos < 0) pos = z.length;
    var erg = z.substr(pos, 100);
    var c = 0;
    for (n = pos - 1; n >= 0; n--) {
        if (c == 3) {
            erg = "." + erg;
            c = 1;
        } else {
            c++;
        }
        erg = z.substr(n, 1) + erg;
    }
    if (negativ) erg = "-" + erg;
    return erg;
};

/**
 * Prueft, ob einen Zahl reine INT ist, oder Nachkommastellen hat
 * @param {number} n
 * @returns {boolean}
 */
hx.isInt = function (n) {
    return n % 1 === 0;
};