/**
 * Prueft, ob eine Variable ein Array ist
 * @param value
 * @returns {boolean}
 */
hx.is_array = function (value) {
    if (Object.prototype.toString.call(value) === '[object Array]') return true;

    return false;
};

/**
 * Wie die PHP-Funktion in_array()
 * Prueft ob ein Array/Objekt[Benanntes Array] einen Wert enthaelt
 *
 * @param {array}        arr        - Array in dem gesucht wird
 * @param {number}        value    - zu suchender Wert
 * @return boolean bool
 */
hx.in_array = function (arr, value) {
    if (arr.length) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == value) return true;
        }
    } else {
        for (var i in arr) {
            if (arr[i] == value) return true;
        }
    }
    return false;
};

/**
 * Entfernt doppelte Werte aus Array (Zahlen werden aufsteigend sortiert, Buchstaben unsortiert im Ergebnisarray zurueckgegeben)
 *
 * @param {number}        arr        - Array in dem gesucht wird
 * @return Array array
 */
hx.array_unique = function (arr) {
    var uniquekeys = new Object();
    var erg = new Array();
    for (var i = 0; i < arr.length; i++) {
        uniquekeys[arr[i]] = 1;
    }
    for (var i in uniquekeys) {
        erg.push(i);
    }
    return erg;
};

/**
 * Zerlegt einen String anhand eines Delimeters in ein Array
 * @param {String} delim
 * @param {String} str
 * @returns {string[]}
 */
hx.explode = function (delim, str) {
    if (!delim) delim = "";
    if (!str) str = "";
    var d = delim + "";
    var s = str + "";
    return s.split(d);
};

/**
 * Fuegt ein Array zu einem String zusammen
 * @param {String} glue
 * @param {Array} arr
 * @returns {string}
 */
hx.implode = function (glue, arr) {
    if (!glue) glue = "";
    if (!hx.is_array(arr)) arr = new Array();
    var g = glue + "";
    return arr.join(g);
};