/**
 * prueft, ob ein String als Dateiname gueltig ist
 * @param {String} filename
 * @returns {boolean}
 */
hx.isValidFilename = function (filename) {
    if (!filename) filename = "";
    filename = filename + "";
    if (filename == "") return false;
    var valid = "abcdefghijklmnopqrstuvwxyz���ABCDEFGHIJKLMNOPQRSTUVWXYZ����0123456789_-.";
    for (var i = 0; i < filename.length; i++) {
        if (valid.indexOf(filename[i]) < 0) return false;
    }
    return true;
};

/**
 * entfernt aus dem Dateinamen alle ungueltigen Zeichen, sodass ein gueltiger Dateiname entsteht
 * @param {String} s - Dateiname
 * @param {String} spacesAs - Zeichen, das Leerzeichen ersetzt (default: _)
 * @returns {string}
 */
hx.makeValidFilename = function (s, spacesAs) {
    if (!s) s = "";
    s = s + "";
    if (s == "") return "noname";
    if (!spacesAs) spacesAs = "_";
    s = hx.replace(s, " ", spacesAs);
    var valid = "abcdefghijklmnopqrstuvwxyz���ABCDEFGHIJKLMNOPQRSTUVWXYZ����0123456789_-.";
    var erg = "";
    for (var n = 0; n < s.length; n++) {
        var c = hx.substr(s, n, 1);
        if (valid.indexOf(c) < 0) {
        } else {
            erg = erg + c;
        }
    }
    return erg;

};

/**
 * Gibt in einem Pfad den obersten Ordner/die oberste Datei aus.
 * Wenn "suffix" mit angegeben wird, wird es am Pfadende gesucht und geloescht.
 *
 * @param {string} str        - Pfad
 * @param {string} suffix    - Suffix das am Pfadende gesucht und entfernt wird
 * @return {string}
 */
hx.basename = function (str, suffix) {
    if (!str) str = "";
    str = str + "";
    str = hx.replace(str, "\\", "/");
    var lastslash = str.lastIndexOf("/");
    if (lastslash == str.length - 1) {
        str = hx.substr(str, 0, str.length - 1);
        lastslash = str.lastIndexOf("/");
    }
    if (suffix) {
        var suffixfound = true;
        for (var i = 0; i < suffix.length; i++) {
            if (suffix[i] != str[str.length - suffix.length + i]) {
                suffixfound = false;
            }
        }
        if (suffixfound) {
            str = hx.substr(str, 0, str.length - suffix.length);
        }
    }
    if (lastslash > 0) {
        return hx.substr(str, lastslash + 1, str.length - 1);
    }
    return hx.replace(str, "/", "");
};

/**
 * Gibt einen Pfad ohne Dateiname aus
 *
 * @param {string} str Pfad
 * @return {string}
 */
hx.dirname = function (str) {
    if (!str) str = "";
    str = str + "";
    str = hx.replace(str, "\\", "/");
    if (str.lastIndexOf("/") == -1) return ".";
    str = hx.substr(str, 0, str.lastIndexOf("/") + 1);
    if (str.length > 1) str = str.replace(/[\/]+$/, "");
    str = str.replace(/^[\.]+$/, ".");
    return str;
};

/**
 * Formatiert die Bytes in Bytes, k-Bytes, M-Bytes, G-Bytes und T-Bytes
 * @param {number} bytes
 * @param {number} decimalPlaces
 * @returns {string}
 */
hx.formatByteSize = function (bytes, decimalPlaces) {
    if (typeof bytes !== 'number') return 0;
    if (!decimalPlaces) decimalPlaces = 0;

    if (bytes < 1000) return hx.formatPreis(bytes, decimalPlaces) + " Byte";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " kB";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " MB";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " GB";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " TB";
    return hx.formatPreis((bytes /= 1024), decimalPlaces) + "PB";
};