function libhx_anw() {
}

libhx_anw.open_help = function (root, modulename, helpkey) {
    window.open(root + "libhx/anw/help/index.php?modulename=" + modulename + "&k=" + helpkey + "",
        "help", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,width=850,height=700,left=100,top=100,resizable=yes,dependent=1").focus();
};

libhx_anw.open_credits = function (root) {
    window.open(root + "libhx/anw/credits/index.php", "credits", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,width=480,height=303,resizable=no,dependent=1").focus();
};

$(document).on("click", ".js-hx_global_msg .hXGuiElementTextBox_icon", function(e){
    e.preventDefault();
    var $currentElement = $(this).parents(".js-hx_global_msg");
    $.ajax({
        url:$currentElement.data("url"),
        type:"GET",
        success:function(response){}
    });
})