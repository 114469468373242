$(document).on("click", ".js-hx_gui_context_menu_icon", function(e){
    e.preventDefault();
    $icon = $(this);

    if($icon.siblings(".js-hx_gui_context_menu").is(":hidden")){
        setTimeout(function(){
            $icon.siblings(".js-hx_gui_context_menu").show();
        }, 10)
    }
    hideContextMenu();
})

$(document).on("click", function(e){
    if(!$(e.target).hasClass("js-hx_gui_context_menu") && !$(e.target).parents().hasClass("js-hx_gui_context_menu")){
        hideContextMenu();
    }
})

function hideContextMenu(){
    $(".js-hx_gui_context_menu").hide();
}