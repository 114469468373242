
class HXJsTest {

    showPassed = true;
    showError = true;

    passed = 0;
    errors = 0;
    total = 0;

    testResult = "";

    runTests(showError = true, showPassed = true){
        this.showPassed = showPassed;
        this.showError = showError;

        var tests = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
        tests.forEach((test) =>  {
            this.testResult = "";
            if (test.startsWith("test")) {
                this.setUp();
                this[test]();
                if (this.testResult.length>0) {
                    console.log("\n"+this.constructor.name+": " + test + this.testResult);
                }
                this.tearDown();
            }
        });
        // Gesamtergebnis ausgeben:
        if (this.errors==0){
            console.log("%c" + $("<div/>").html("&#128994 ").text() + this.constructor.name +  ": Errors: " + this.errors + ", Passed: " + this.passed + ".",'font-weight:bold;color:darkgreen;');
        }else{
            console.log("%c" + $("<div/>").html("&#128308 ").text() + this.constructor.name + ": Errors: " + this.errors + ", Passed: " + this.passed + ".",'font-weight:bold;color:red;');
        }
        return this.errors;
    }

    testcase(testname, ergSoll, ergIST) {
        this.total++;

        var ergSollString = ergSoll;
        var ergISTString = ergIST;

        if ((typeof ergSoll) === 'object' || (typeof ergIST) === 'object') {
            ergSollString = JSON.stringify(ergSoll);
            ergISTString = JSON.stringify(ergIST);
        }

        if (ergSollString === ergISTString) {
            if (this.showPassed===true) this.testResult+= "\n" + $("<div/>").html("&#128578 ").text()+testname;
            this.passed++;
        } else {
            if (this.showError===true) this.testResult+= "\n" + $("<div/>").html("&#128127 ").text()+testname + ": SOLL: '" + JSON.stringify(ergSoll) + "' != IST: '" + JSON.stringify(ergIST) + "'";
            this.errors++;
        }
    }
}