/**
 * GET-Parameter auslesen aus URL (zB document.location.href)
 *
 * @param str  http://www.hannex.at/index.php?id=5&seite=start
 * @returns {Object} Benanntes Array mit allen Parametern
 */
hx.urlParams2Array = function (str) {
    str = hx.explode('#', str)[0];
    str = hx.basename(str);
    if (str.indexOf('?') < 0) return new Object();
    str = str.substring(str.indexOf('?') + 1);
    var t = str.split('&');
    var erg = new Object();
    for (var i in t) {
        var t2 = t[i].split("=");
        if (t2[1]) erg[t2[0]] = decodeURIComponent(t2[1]);
    }
    return erg;
};

/**
 * HTTP-GET-Request ausfuehren.
 * Wenn eine Callback-Funktion angegeben ist, wird der Request asynchron
 *
 * @param {string} url                - Ziel
 * @param {string} callbackfunction    - Funktionsname
 * @return {string}
 * @deprecated
 * @see Jquery.ajax
 */
hx.httpRequest = function (url, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            http_request.overrideMimeType('text/plain');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");

            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) {
        http_request.onreadystatechange =
            function () {
                if (http_request.readyState == 4) {
                    callbackfunction(http_request.responseText);
                }
            };
    }
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('GET', url, true);
            http_request.send(null);
        } else {
            http_request.open('GET', url, false);
            http_request.send(null);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
};

/**
 * HTTP-POST-Request ausfuehren.
 * Wenn eine Callback-Funktion angegeben ist, wird der Request asynchron
 *
 * @param {string} url                - Ziel
 * @param {string} postdata            - Parameter fuer den Request
 * @param {string} callbackfunction    - Funktionsname
 * @return {string|boolean}
 * @deprecated
 * @see Jquery.ajax
 */
hx.httpPostRequest = function (url, postdata, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) {
        http_request.onreadystatechange =
            function () {
                if (http_request.readyState == 4) {
                    callbackfunction(http_request.responseText);
                }
            };
    }
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('POST', url, true);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
        } else {
            http_request.open('POST', url, false);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
};

/**
 * Escape eines Strings als URL-uebergabe-Parameter-Wert
 *
 * @param {String} text
 * @return {String}
 */
hx.escape = function (text) {

    text = encodeURIComponent(text);
    text = text.replace(/[+]/g, '%2b');
    text = text.replace(/[']/g, '%27');
    text = text.replace(/[!]/g, '%21');
    text = text.replace(/[(]/g, '%28');
    text = text.replace(/[)]/g, '%29');
    text = text.replace(/[~]/g, '%7E');

    return text;
};

/**
 * Aus einem JS-Objekt einen String erzeugen in der Form:
 * isPOST :  eins=1&zwei=2&drei=3
 * !isPOST:  ?eins=1&zwei=2&drei=3
 *
 * @param {Object} obj
 * @param {Boolean} isPOST
 * @returns {string}
 */
hx.createRequestParamString = function (obj, isPOST) {
    var str = "";
    var i = 0;
    var separator = "";
    if (!isPOST) separator = "?";
    hx.foreach(obj, function (elem, key) {
        if (i > 0) separator = "&";
        str += separator + key + "=" + hx.escape(elem);
        i++;
    });
    return str;
};

/**
 *  Ajax-Request-Klasse
 *  @deprecated
 *  @see Jquery.ajax
 */
hx.httpRequestObject = function () {

    var paramsGET = new Object;
    var paramsPOST = new Object;
    var callbackFunc = null;
    var actionstring = "";
    var response = "";

    this.setCallbackFunc = function (func) {
        callbackFunc = func;
    };

    this.setAction = function (a) {
        actionstring = a;
    };

    this.addGETParam = function (name, value) {
        if (hx.is_array(value) || hx.is_object(value)) {
            addParamArray(paramsGET, name, value);
            return;
        }
        paramsGET[name] = value;
    };

    this.addPOSTParam = function (name, value) {
        if (hx.is_array(value) || hx.is_object(value)) {
            addParamArray(paramsPOST, name, value);
            return;
        }
        paramsPOST[name] = value;
    };

    this.exec = function () {
        var postStr = hx.createRequestParamString(paramsPOST, true);
        var getStr = hx.createRequestParamString(paramsGET);

        if (callbackFunc != null) {
            if (hx.count(paramsPOST) > 0) {
                hx.httpPostRequest(actionstring + getStr, postStr, callbackFunc);
            } else {
                hx.httpRequest(actionstring + getStr, callbackFunc);
            }
        } else {
            if (hx.count(paramsPOST) > 0) {
                response = hx.httpPostRequest(actionstring + getStr, postStr);
            } else {
                response = hx.httpRequest(actionstring + getStr, callbackFunc);
            }
            return response;
        }
    };

    this.getResponse = function () {
        return response;
    };

    var addParamArray = function (params, name, arr, keys) {
        if (!hx.is_array(keys)) keys = new Array();
        if (hx.is_array(arr) || hx.is_object(arr)) {
            hx.foreach(arr, function (v, k) {
                var nextKey = keys.slice();
                nextKey.push(k);
                addParamArray(params, name, v, nextKey);
            });
        } else {
            var key = "";
            hx.foreach(keys, function (k) {
                key += "[" + k + "]";
            });
            if (arr === true || arr === false) arr = hx.cint(arr);
            params[(name + '' + key + '')] = arr;
        }
    };

    return this;
};