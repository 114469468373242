/**
 * Wandelt einen String in Grossbuchstaben um
 * @param {string} str
 * @returns {string}
 */
hx.strtoupper = function (str) {
    var t = str + "";
    return t.toUpperCase();
};

/**
 * Wandelt einen String in Kleinbuchstaben um
 * @param {string} str
 * @returns {string}
 */
hx.strtolower = function (str) {
    var t = str + "";
    return t.toLowerCase();
};

/**
 * Ersetzt String in String durch String
 * @param {string} textstr
 * @param {string} findstr
 * @param {string} repstr
 * @returns {string}
 */
hx.replace = function (textstr, findstr, repstr) {
    var t = '', f = '', r = '', n = 0, npos = 0;
    t = '' + textstr;
    f = '' + findstr;
    r = '' + repstr;
    if ((f != "") && (f != r)) {
        npos = t.indexOf(f);
        while (npos >= 0) {
            t = t.substr(0, npos) + r + t.substr(npos + f.length);
            n = npos + r.length;
            npos = t.substr(n).indexOf(f);
            if (npos >= 0) npos = npos + n;
        }
    }
    return t;
};

/**
 * Prueft, ob eine Variable ein String ist
 * @param value
 * @returns {boolean}
 */
hx.is_string = function (value) {
    if (typeof value === "string") return true;

    return false;
};

/**
 * Prueft ob der erste Wert in den nachfolgenden Werten vorkommt
 *
 * @param {string} needle
 * @param {string} haystack1
 * @param {string} haystack2
 * @returns {boolean}
 */
hx.value_in = function (needle, haystacks1, haystack2) {
    for (var i = 1; i < arguments.length; i++) {
        if (needle == arguments[i]) return true;
    }
    return false;
};

/**
 * wie PHP-Funktion substr()
 * @param {String} str
 * @param {int} start
 * @param {int} length
 * @returns {string}
 */
hx.substr = function (str, start, length) {
    if (!str) str = "";
    var t = str + "";
    if (length) {
        return t.substr(start, length);
    } else {
        return t.substr(start);
    }
};

/**
 * Strings die laenger sind als "length" werden abgeschnitten
 * und es wird ein "..." angehaengt
 *
 * @param {String} str
 * @param {int} length
 * @returns {string}
 */
hx.leftcut = function (str, length) {
    if (!str) str = "";
    var t = str + "";
    if (t.length > length) {
        return t.substr(0, length) + "...";
    } else {
        return t;
    }
};

/**
 * Strings die laenger sind als "length" werden abgeschnitten
 * und es wird ein "..." angehaengt. Der ganze String wird als Tooltip ausgegeben.
 *
 * @param {string} str
 * @param {number} length
 * @return {string} HTML-Element (span) mit Title-Attribut
 */
hx.leftcuttitle = function (str, length) {
    if (!str) str = "";
    var s = str + "";
    if (s.length > length) {
        var t = document.createElement("span");
        t.title = s;
        t.innerHTML = s.substr(0, length) + "...";
        return t;
    } else {
        return s;
    }
};

/**
 * White-Spaces werden auf der linken und rechten Seite entfernt.
 * Wenn der Browser "trim" unterstuetzt wird seine eigene Funktion verwendet.
 * Ansonsten wird eigene Implementierung verwendet.
 *
 * @param {string} str                 der zu trimmende String
 * @param {string} trimchar         Zeichen welche vom Trim gesucht werden und entfernt werden (optional)
 * @return {string}
 */
hx.trim = function (str, trimchar) {
    if (!str) str = "";
    var t = str + "";
    if (trimchar) {
        // Schluesselzeichen escapen
        trimchar = hx.replace(trimchar, "[", "\\[");
        trimchar = hx.replace(trimchar, "]", "\\]");
        trimchar = hx.replace(trimchar, "(", "\\(");
        trimchar = hx.replace(trimchar, ")", "\\)");
        trimchar = hx.replace(trimchar, "{", "\\{");
        trimchar = hx.replace(trimchar, "}", "\\}");
        trimchar = hx.replace(trimchar, "^", "\\^");
        trimchar = hx.replace(trimchar, "$", "\\$");
        trimchar = hx.replace(trimchar, ".", "\\.");
        while (t.charAt(0).search(new RegExp('^[' + trimchar + ']+', 'g')) >= 0) {
            t = t.substr(1, t.length - 1);
        }
        while (t.charAt(t.length - 1).search(new RegExp('^[' + trimchar + ']+', 'g')) >= 0) {
            t = t.substr(0, t.length - 1);
        }
        return t;
    } else if (!String.prototype.trim) {
        return t.replace(/^\s+|\s+$/g, '');
    } else {
        return t.trim();
    }
};

/**
 * Alle Tags im String werden entfernt.
 *
 * @param {string}        str
 * @return {string}
 */
hx.strip_tags = function (str) {
    if (!str) str = "";
    str = str + "";
    return str.replace(/(<([^>]+)>)/gi, "");
};

/**
 * Pruefen ob String mit Prefix beginnt
 *
 * @param {String} str
 * @param {String} prefix
 * @returns {boolean}
 */
hx.startsWith = function (str, prefix) {
    if (str.indexOf(prefix) == 0) return true;
    return false;
};

/**
 * Pruefen ob String mit Suffix endet
 *
 * @param {String} str
 * @param {String} suffix
 * @returns {boolean}
 */
hx.endsWith = function (str, suffix) {
    const endString = str.substring(str.length - suffix.length);

    if (endString == suffix) return true;

    return false;
};

/**
 * Prueft auf gueltige Emailadresse
 * Seit 24.11.2017 neue Logik mit erweiterter Pruefung:
 *  - Generell erlaubte Zeichen sind A-Z a-z 0-9 - _ + . @
 *  - Keine Umlaute
 *  - Kein scharfes S
 *  - Keine Leerzeichen
 *  - Erstes und letztes Zeichen muessen gueltig sein
 *  - Kein ".."
 *
 * @param {string} email
 * @return {boolean}
 */
hx.isValidEmailAddress = function (email) {
    if (!email) return false;
    var re = /^(([A-Za-z0-9\-\_\+\&]+(\.[A-Za-z0-9\-\_\+\&]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
};

/**
 * Prueft auf gueltige URL
 * allowedProtocols sind standardmae�ig "http","https","ftp" und "ftps"
 * kann geaendert werden mit uebergebenem array
 *
 * @param {String} url
 * @param {Array} allowedProtocols -  Array
 * @returns {boolean}
 */
hx.isValidUrl = function (url, allowedProtocols) {
    // geaendert 01.12.2017 SF
    if (url == "") return false;

    if (!allowedProtocols) allowedProtocols = new Array("http", "https", "ftp", "ftps");
    //array und implode
    if (Array.isArray(allowedProtocols)) allowedProtocols = hx.implode("|", allowedProtocols);

    //  ('^((https|http|ftp|ftps):\\/\\/)'+ // protocol
    // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    // '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    // '(\\:\\d+)?(\\/{1}[-a-z\\d%_.~+]*)*'+ // port and path
    // '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    // '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    var url_pattern = new RegExp('^(((' + allowedProtocols + '):\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/{1}[-a-z\\d%_.~+:]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$)|javascript:\d*', 'i');
    if (!url_pattern.test(url)) return false;

    return true;
};

/**
 * Wie PHP-Funktion print_r
 * Formatierte Ausgabe eines Arrays/Objekts
 *
 * @param {Array}            arr
 * @param {number}        level (automatisch)
 * @return {string}
 */
hx.print_r = function (arr, level) {
    var dumped_text = "";
    if (!level) level = 0;

    if (level > 10) return "Max 6 levels werden aufgelistet";

    //The padding given at the beginning of the line.
    var level_padding = "";
    var bracket_level_padding = "";

    for (var j = 0; j < level + 1; j++) level_padding += "    ";
    for (var b = 0; b < level; b++) bracket_level_padding += "    ";

    if (typeof(arr) == 'object') { //Array/Hashes/Objects
        dumped_text += "Array\n";
        dumped_text += bracket_level_padding + "(\n";
        for (var item in arr) {

            var value = arr[item];

            if (typeof(value) == 'object') { //If it is an array,
                dumped_text += level_padding + "[" + item + "] => ";
                dumped_text += hx.print_r(value, level + 2);
            } else {
                dumped_text += level_padding + "[" + item + "] => " + value + "\n";
            }

        }
        dumped_text += bracket_level_padding + ")\n\n";
    } else { //Stings/Chars/Numbers etc.
        dumped_text = "===>" + arr + "<===(" + typeof(arr) + ")";
    }

    return dumped_text;
};

/**
 * Prueft ob die Zeichen die in "str" uebergeben wurden alle in "format" vorkommen.
 * Vordefinierte Formate koennen mit format=azAZ (Buchstaben), format=azAZ09 (Alphanumerisch) und format=FILENAME geprueft werden.
 *
 * @param {string} str
 * @param {string} format Anzahl der anzuzeigenden Nachkommastellen
 * @return {string|boolean}
 */
hx.isValidString = function (str, format) {
    var a = "";
    if (format == 'azAZ') {
        a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'azAZ09') {
        a = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'FILENAME') {
        a = '0123456789_-.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else {
        a = format;
    }
    var c = '';
    merker = true;
    for (var n = 0; n < str.length; n++) {
        c = str.substr(n, 1);
        if (a.indexOf(c) < 0) {
            merker = false;
            break;
        }
    }
    return merker;
};

/**
 * Fuellt einen String auf mit Leerzeichen bis er die gewuenschte Laenge erreicht.
 *
 * @param {string} t                - text
 * @param {string} len            - Laenge die erreicht werden soll
 * @param {string} char            - Fuellzeichen
 * @return {string}
 */
hx.fixLen = function (t, len, char) {
    if (!t) t = "";
    t = t + "";
    if (!char) char = " ";
    while (t.length < len) {
        t = t + char;
    }
    return t.substr(0, len);
};

/**
 * Prueft ob das DOM-Document UTF8 kodiert ist
 * @returns {boolean}
 */
hx.isUTF8 = function () {
    var c = "";
    if (document.charset) c = document.charset;
    if (document.characterSet) c = document.characterSet;
    return hx.replace(c.toUpperCase(), "-", "") == "UTF8";
};

/**
 * Dreht den String um
 * @param {String} string
 * @returns {String}
 */
hx.stringReverse = function (string) {
    return string.split("").reverse().join("");
};