/**
 * Erstellt ein Unix Timestamp (in sekunden) aus den angegebenen Parametern. (00:00:00 Uhr)
 *
 * @param {number} jahr
 * @param {number} monat
 * @param {number} tag
 *
 * @return {number}
 */
hx.dateSerial = function (jahr, monat, tag) {
    var d = new Date(0);
    monat--;
    d.setHours(0, 0, 0, 0);
    d.setFullYear(jahr);
    d.setMonth(monat);
    d.setDate(tag);
    return d.getTime();
};

/**
 * Erstellt ein Date-Objekt aus den angegebenen Parametern. (01.01.1970)
 *
 * @param {number} stunde
 * @param {number} minute
 * @param {number} sekunde
 *
 * @return {number}
 */
hx.timeSerial = function (stunde, minute, sekunde) {
    var d = new Date(0);
    var offset = d.getTimezoneOffset() / 60;
    if (offset < 0) offset = offset * -1;
    d.setHours(stunde + offset);
    d.setMinutes(minute);
    d.setSeconds(sekunde);
    return d.getTime();
};

/**
 * Erstellt ein Date-Objekt aus den angegebenen Parametern.
 *
 * @param {number} jahr
 * @param {number} monat
 * @param {number} tag
 * @param {number} stunde
 * @param {number} minute
 * @param {number} sekunde
 *
 * @return {number}
 */
hx.dateTimeSerial = function (jahr, monat, tag, stunde, minute, sekunde) {
    var d = new Date(0);
    monat--;
    d.setHours(0, 0, 0, 0);
    d.setFullYear(jahr);
    d.setMonth(monat);
    d.setDate(tag);
    d.setHours(stunde);
    d.setMinutes(minute);
    d.setSeconds(sekunde);
    return d.getTime();
};

/**
 * Holt Jahreszahl aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.year = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getFullYear();
};


/**
 * Gibt die zweistellige Jahreszahl eines Datums zurueck
 * Funktioniert NUR fuer Jahr >1999
 *
 * @param date
 * @return {number}
 */
hx.yearShort = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    var year = hx.cint(date.getYear());

    if (year > 99) {
        return year - 100;
    } else {
        return year;
    }
};

/**
 * Holt Halbjahr aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.halfYear = function (date) {
    if (!date || date + "" == "") return "";
    return hx.ceil(hx.month(date) / 6);
};

/**
 * Holt Quartal aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.quarterYear = function (date) {
    if (!date || date + "" == "") return "";
    return hx.ceil(hx.month(date) / 3);
};

/**
 * Holt Monat (Nr) aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.month = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getMonth() + 1;
};

/**
 * Holt Tag (Nr) aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.day = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getDate();
};

/**
 * Gibt Mo, Di etc. zurueck
 *
 * @param {number} integer
 * @return {string} Mo, Di, Mi, ...
 */
hx.dayShort = function (integer) {
    if (!hx.is_numeric(integer)) return "";

    var date = new Date(integer);
    var weekday = date.getDay();

    if (weekday == 0) return "So";
    if (weekday == 1) return "Mo";
    if (weekday == 2) return "Di";
    if (weekday == 3) return "Mi";
    if (weekday == 4) return "Do";
    if (weekday == 5) return "Fr";
    if (weekday == 6) return "Sa";
};

/**
 * Holt Stunde aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.hour = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getHours();
};

/**
 * Holt Minute aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.minute = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getMinutes();
};

/**
 * Holt Sekunde aus einem Date-Objekt
 *
 * @param date
 * @return {number}
 */
hx.second = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getSeconds();
};

/**
 * Erstellt ein Datums-Objekt mit dem aktuellen Datum.
 *
 * @param {boolean} withCurrTime Setzt aktuelle Uhrzeit auf jetzt oder auf 00:00:00
 * @return {number}
 */
hx.now = function (withCurrTime) {
    var d = new Date();
    if (withCurrTime === false) {
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
    }
    d.setMilliseconds(0);
    return d.getTime();
};

/**
 * Addiert Parameter zu Datum
 *
 * @param date
 * @param {number} y zu addierende Jahre
 * @param {number} m zu addierende Monate
 * @param {number} d zu addierende Tage
 * @param {number} h zu addierende Stunden
 * @param {number} n zu addierende Minuten
 * @return {number}
 */
hx.dateTimeAdd = function (date, y, m, d, h, n) {
    date = new Date(date);
    if (y) date.setFullYear(date.getFullYear() + y);
    if (m) date.setMonth(date.getMonth() + m);
    if (d) date.setDate(date.getDate() + d);
    if (h) date.setHours(date.getHours() + h);
    if (n) date.setMinutes(date.getMinutes() + n);
    return date.getTime();
};

/**
 * Addiert Parameter zu Datum
 *
 * @param date
 * @param {number} y zu addierende Jahre
 * @param {number} m zu addierende Monate
 * @param {number} d zu addierende Tage
 * @return {number}
 */
hx.dateAdd = function (date, y, m, d) {
    date = new Date(date);
    if (y) date.setFullYear(date.getFullYear() + y);
    if (m) date.setMonth(date.getMonth() + m);
    if (d) date.setDate(date.getDate() + d);
    return date.getTime();
};

/**
 * Zeitlichen Unterschied zwischen zwei Datums-Objekten ausrechnen.
 * date2-date1
 *
 * @param {string} interval    - "w" in Wochen, "d" in Tagen, "h" in Stunden, "n" in Minuten, "s" in Sekunden
 * @param {number} date1
 * @param {number} date2
 * @return {number}
 */
hx.dateDiff = function (interval, date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    var timedifference = date2.getTime() - date1.getTime();
    var corr = Math.abs(date2.getTimezoneOffset()) - Math.abs(date1.getTimezoneOffset());
    corr = corr * 60 * 1000;
    timedifference += corr;

    switch (interval) {
        case "w":
            return hx.cint(timedifference / 604800000);
        case "d":
            return hx.cint(timedifference / 86400000);
        case "h":
            return hx.cint(timedifference / 3600000);
        case "n":
            return hx.cint(timedifference / 60000);
        case "s":
            return hx.cint(timedifference / 1000);
    }
};

/**
 * Datums-Objekt in hh:mm formatieren
 *
 * @param {number} date
 * @return {string}
 */
hx.formatTime = function (date) {
    if (hx.cint(date) == 0) return "";
    date = new Date(date);
    return hx.format00(date.getHours()) + ":" + hx.format00(date.getMinutes());
};

/**
 * Datums-Objekt in tt.mm.yyyy formatieren
 *
 * @param date
 * @return {string}
 */
hx.formatDate = function (date) {
    if (!hx.is_numeric(date)) return "";
    date = new Date(date);
    return hx.format00(date.getDate()) + "." + hx.format00(date.getMonth() + 1) + "." + date.getFullYear();
};

/**
 * Datums-Objekt in tt.mm.yy formatieren
 *
 * @param date
 * @return {string}
 */
hx.formatDateShort = function (date) {
    if (!hx.is_numeric(date)) return "";
    date = new Date(date);
    return hx.format00(date.getDate()) + "." + hx.format00(date.getMonth() + 1) + "." + date.getFullYear().toString().substr(2, 2);
};

/**
 * Datums-Objekt in tt.mm.yyyy hh:mm formatieren
 *
 * @param date
 * @return {string}
 */
hx.formatDateTime = function (date) {
    if (!hx.is_numeric(date)) return "";
    date = new Date(date);
    return hx.format00(date.getDate()) + "." + hx.format00(date.getMonth() + 1) + "." + date.getFullYear() + " " + hx.format00(date.getHours()) + ":" + hx.format00(date.getMinutes());
};

/**
 * Kalenderwoche eines Datums-Objekts ausgeben
 *
 * @param date
 * @return {number}
 */
hx.date2kw = function (date) {
    if (hx.cint(date) == 0) return;
    date = new Date(date);
    var jahr = date.getFullYear();
    var jh = jahr + 1;
    var kalwo = hx._kaldiff(date, jh);
    while (kalwo < 1) {
        jh--;
        kalwo = hx._kaldiff(date, jh);
    }
    return kalwo;
};

hx._kaldiff = function (datum, jahr) {
    var d4j = new Date(jahr, 0, 4);
    var wt4j = (d4j.getDay() + 6) % 7;
    return Math.floor(1.05 + (datum.getTime() - d4j.getTime()) / 6048e5 + wt4j / 7);
};

/**
 * Ersten Wochentag der Kombination KW+Jahr herausfinden
 *
 * @param {number} kw
 * @param {number} jahr
 * @returns {number}
 */
hx.kw2date = function (kw, jahr) {
    var datum = hx.text2date("01.07." + jahr);
    while (hx.date2kw(datum) > kw && hx.year(datum) == jahr) datum = hx.dateAdd(datum, 0, 0, -1);
    while (hx.date2kw(datum) < kw && hx.year(datum) == jahr) datum = hx.dateAdd(datum, 0, 0, 1);
    // auf Sonntag der vorherigen KW "zurueckfahren"
    while (hx.date2kw(datum) == kw) datum = hx.dateAdd(datum, 0, 0, -1);
    return hx.dateAdd(datum, 0, 0, 1);
};

/**
 * Ein deutsch formatiertes Datum aus einem String
 * auslesen und in ein Datums-Objekt umwandeln
 *
 * @param {string} str
 * @return {string}
 */
hx.text2date = function (str) {
    if (!str) str = "";
    str = str + "";
    var t1 = str;
    var t2 = t1;
    var d, datum, dat_donn, dat_mon;
    var t = 0, m = 0, j = 0;
    var tx = 0, mx = 0, jx = 0;
    if (t1 == "") {
        return null;
    }
    var tmp = t1.split(".");
    if (tmp.length != 3 && tmp.length != 1) {
        return null;
    }
    if (tmp.length == 1) {
        if (t2.length == 8) {
            tx = t2.substr(0, 2);
            mx = t2.substr(2, 2);
            jx = t2.substr(4, 4);
        } else if (t2.length == 6) {
            tx = t2.substr(0, 2);
            mx = t2.substr(2, 2);
            jx = t2.substr(4, 2);
        } else if (t2.length == 4) {
            kwx = t2.substr(0, 2);
            jx = t2.substr(2, 2);
            j = parseInt(jx, 10);
            kw = parseInt(kwx, 10);
            if (isNaN(kw) || isNaN(j)) {
                return null;
            }
            kw = kw - 1;
            j = j + 2000;
            // ersten Donnerstag des Jahres:
            dat_donn = new Date(j, 0, 1);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 2);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 3);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 4);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 5);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 6);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 7);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 8);

            // Jetzt Montag suchen
            dat_mon = dat_donn;
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);

            datum = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() + (kw * 7));
            tx = datum.getDate();
            mx = datum.getMonth() + 1;
            jx = datum.getFullYear();
        } else {
            return null;
        }
        t = parseInt(tx, 10);
        m = parseInt(mx, 10);
        j = parseInt(jx, 10);
    }
    if (tmp.length == 3) {
        t = parseInt(tmp[0], 10);
        m = parseInt(tmp[1], 10);
        j = parseInt(tmp[2], 10);
    }
    if (isNaN(t) || isNaN(m) || isNaN(j)) {
        return null;
    }
    if (t < 1 || t > 31) {
        return null;
    }
    if (m < 1 || m > 12) {
        return null;
    }
    if (j < 70 && j >= 0) {
        j = j + 2000;
    }
    if (j >= 70 && j < 100) {
        j = j + 1900;
    }
    if (j < 1900 || j > 2100) {
        return null;
    }
    d = new Date(j, m - 1, t);

    return d.getTime();
};

/**
 * Eine deutsch formatierte Uhrzeit aus einem String
 * auslesen und in ein Datums-Objekt umwandeln
 *
 * @param {string} txt
 * @return {string}
 */
hx.text2time = function (txt) {
    if (txt.replace(/[0-9]{1,2}:?[0-9]{1,2}:?[0-9]{0,2}/, "") != "") return ""; // Keine gueltige Eingabe
    if (txt.indexOf(":") < 0) {
        txt = txt.substr(0, 2) + ":" + txt.substr(2, 2) + ":" + txt.substr(4, 2);
    }
    var t = txt.split(":");
    for (var i in t) t[i] = hx.cint(t[i]);
    if (t.length < 3) {
        t.push(0);
        t.push(0);

    }
    if (t[0] >= 0 && t[0] < 24 && t[1] >= 0 && t[1] < 60 && t[2] >= 0 && t[2] < 60) {
        return hx.dateTimeSerial(1970, 1, 1, t[0], t[1], t[2]);
    }
    return "";
};

/**
 * Ein deutsch formatiertes Datum+Uhrzeit aus einem String
 * auslesen und in ein Datums-Objekt umwandeln
 *
 * @param {string} str
 * @return {string}
 */
hx.text2datetime = function (str) {
    var txt = hx.replace(str, " ", ".");
    txt = hx.replace(txt, ":", ".");
    var a = hx.explode(".", txt);
    var t = hx.cint(a[0]);
    var m = hx.cint(a[1]);
    var j = hx.cint(a[2]);
    var h = hx.cint(a[3]);
    var n = hx.cint(a[4]);
    var erg;
    if (t > 0 && t < 32 && m > 0 && m < 13 && j > 0 && j < 3000) {
        if (h >= 0 && h <= 23 && n >= 0 && n <= 59) {
            erg = hx.dateTimeAdd(hx.dateSerial(j, m, t), 0, 0, 0, h, n);
        } else {
            erg = hx.dateSerial(j, m, t);
        }
        return erg;
    }
    return "";
};